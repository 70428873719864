<div [ngClass]="darkTheme ? 'dark-theme-background' : 'light-theme-background'" class="container-fluid px-0 col-12"  id="coinInfo">
  <div *ngIf="!showCoinInfo && !routeCoin" class="pt-5 pb-3 blueprint-background"> <!-- start blueprint background -->
      <ng-container class="supportedCoins" *ngTemplateOutlet="supportedCoins"></ng-container>
  </div><!-- end blueprint background -->

  <ng-container *ngIf="showCoinInfo">
    <div class="row mx-0">
      <!-- start blueprint background -->
      <div class="blueprint-background">
        <div class="col-12 col-lg-9 mt-1 mt-lg-5 mx-auto coin d-flex justify-content-center">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-auto">
              <div class="row col-12 mx-0">
                <div class="col-12 d-flex justify-content-center" [ngClass]="{'blur': moreCoins}">
                  <img class="coin-icon coin-image d-block mr-2" [src]="imageUrl + selectedCoin.code.toLowerCase() + '.png'" (error)="$event.target.src = imageUrl + 'fallback_coin_icon.png'">
                  <h1 class="header">{{selectedCoin.name}} ({{selectedCoin.code}})</h1>
                </div>
                <!-- coin cards toggle -->
                <div class="col-12 d-flex justify-content-center">
                  <button type="button"
                    class="border-0 btn-temp px-2 col-auto ml-1 d-flex align-items-center" (click)="coinCardsToggle()">
                      MORE COINS
                    <mat-icon class="expand-coins" [ngClass]="{'expand-more': moreCoins}">expand_more</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="moreCoins">
          <ng-container class="supportedCoins" *ngTemplateOutlet="supportedCoins"></ng-container>
        </div>

        <div *ngIf="selectedCoin?.id !== 0" [ngClass]="{'dark-primary-bg': darkTheme, 'primary-bg': !darkTheme, 'blur': moreCoins}">
          <div class="col-12 col-lg-9 my-1 my-lg-5 py-1 py-lg-5 mx-auto about">
            <div class="row d-flex justify-content-center px-3 pb-2">
              <div class="col-12 px-1 about-top">
                <h2 class="text-center pb-3">About <span class="emphasis-heading orange-text"><b>{{selectedCoin.name}}</b></span></h2>
                <p class="d-block" *ngFor="let information of selectedCoin.extraData?.information">{{ information }}</p>
              </div>
              <div [ngClass]="darkTheme ? 'dark-box':'light-box'" class="px-4 py-4 color-black col h-100 my-auto resources">
                <ng-container *ngTemplateOutlet="resources"></ng-container>
              </div>
              <div class="col-8 pl-3 pl-xl-5 d-none about-bottom">
                <h2 class="text-center pb-3">About <span class="emphasis-heading orange-text"><b>{{selectedCoin.name}}</b></span></h2>
                <p class="d-block"  *ngFor="let information of selectedCoin.extraData?.information">{{ information }}</p>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- end blueprint background -->

      <ng-container *ngIf="filteredMarkets?.length > 0">
        <div class="col-12 col-lg-9 pt-lg-5 mx-auto market spacing-header" [ngClass]="moreCoins ? 'blur' : null">
          <h2 class="text-center">Our <span class="emphasis-heading orange-text"><b>{{selectedCoin.code}}</b></span> Markets</h2>
          <h4 class="text-center">See how this coin is trading on ChainEX, by selecting your preferred market below.</h4>
        </div>
        <!-- start map background -->
        <div class="map-background">
          <div class="col-12 col-lg-9 my-1 my-lg-5 pt-1 py-lg-5 mx-auto pt-0"  [ngClass]="moreCoins ? 'blur' : null">
            <div class="btn-group row mx-0" role="group">
              <div *ngFor="let market of filteredMarkets" class="col tabs border-0 px-0"
                [ngClass]="{'selected-tab orange-gradient': market.tabSelected, 'dark-box': darkTheme, 'light-box': !darkTheme}">
                <button type="button" [ngClass]="{'selected-tab orange-gradient': market.tabSelected, 'btn-primary-dark': darkTheme}"
                  class="btn tabs btn-primary col-auto font-weight-bold" (click)="setSelectedMarket(market)">
                  {{market.coin}}/{{market.exchange}}</button>
              </div>
            </div>
            <div [ngClass]="darkTheme ? 'dark-box':'light-box'" class="col-12 px-0 py-3 h-auto d-flex justify-content-center"
              *ngIf="selectedMarket">
              <div class="row px-auto col-12">
                <div class="col-12 col-8-sm h-auto">
                  <div class="col-12 pl-0">
                    <h2 class="price"><span class="emphasis-heading orange-text"><b>{{selectedMarket.exchange}} {{marketService.activeMarket.lastPrice | decimalNumber: marketService.activeMarket.exchangeDecimals}}</b></span>
                    </h2>
                  </div>
                  <!-- market graph goes here-->
                  <div class="market-card-container">
                    <home-market-card [market]="marketDataSelected" [showChartOnly]="true" [darkTheme]="darkTheme" *ngIf="marketDataSelected"></home-market-card>
                  </div>
                </div>
                <div class="col">
                  <!-- time selector and price converter -->
                  <!-- time selector goes here -->
                  <div [ngClass]="darkTheme ? 'dark-stats-shadow':'stats-shadow'">
                    <home-coin-price-calculator [darkTheme]="darkTheme"></home-coin-price-calculator>
                  </div>
                </div>
                <div class="col-12 top-border">
                  <markets-top-stats [setPageTitle]="false" [showPairName]="false"></markets-top-stats>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end map background -->
      </ng-container>
    </div>

    <!-- start blueprint flipped background -->
    <div *ngIf="showITB" class="blueprint-background-flipped" [ngClass]="moreCoins ? 'blur' : null">
      <div class="col-12 py-5 mb-5 mb-lg-4 col-lg-9 mx-auto">
        <div class="mb-1 mb-lg-5 pb-1">
          <h2 class="text-center">Coin <span class="emphasis-heading orange-text"><b>Metrics</b></span></h2>
          <h4 class="text-center">Useful insight on {{selectedCoin.code}} from our partner intotheblock</h4>
        </div>
        <div class="mat-elevation-z2">
          <markets-coin-analytics></markets-coin-analytics>
        </div>
      </div>
    </div> <!-- end blueprint flipped background -->
  </ng-container>

  <ng-template #resources>
    <div class="mx-auto d-flex justify-content-center">
      <div class="px-0 w-100">
        <h2 class="pb-3 text-center">Resources</h2>
        <div class="row justify-content-center">
          <div class="col-12 col-xl-11 pl-2 my-3" *ngIf="selectedCoin.extraData?.website">
            <a class="coin-links d-flex" href="{{selectedCoin.extraData?.website}}" target="_blank">
              <img src="/assets/chainex/images/icons/website.svg" class="mr-2 coin-web image-container"
                loading="lazy">
              <span class="span-size">Official Website</span>
            </a>
          </div>

          <ng-container *ngIf="selectedCoin.extraData?.blockExplorer">
            <div class="col-12 col-xl-11 pl-2">
              <div class="row">
                <div class="col-auto pr-0">
                  <img src="/assets/chainex/images/icons/blockexplorer.svg" class="mr-2 coin-web image-container"
                    loading="lazy">
                </div>
                <div class="col pl-0 pr-0 pr-xl-2">
                  <span>Block Explorers</span>

                  <div class="row mx-0"
                    *ngIf="selectedCoin.extraData?.networkBlockExplorers.length > 1; else defaultNetwork">
                    <ng-container *ngFor="let blockexplorer of selectedCoin.extraData.networkBlockExplorers">
                      <div class="col-4 col-sm-12 col-lg-4 pl-0 pr-2 mb-3"
                        *ngIf="blockexplorer.networkName != 'default' && blockexplorer.active === true">
                        <a class="coin-links explorer-link white-text w-100 px-0 mat-raised-button mat-primary btn"
                          href="{{blockexplorer.link}}" target="_blank">
                          {{blockexplorer.networkName}}
                        </a>
                      </div>
                    </ng-container>
                  </div>
                  <ng-template #defaultNetwork>
                    <div class="col-4 col-sm-6 col-lg-4 pl-0 pr-2 mb-3">
                      <a class="coin-links explorer-link white-text w-100 px-0 mat-raised-button mat-primary btn"
                        href="{{selectedCoin.extraData.blockExplorer}}" target="_blank">
                        {{selectedCoin.defaultNetwork ? selectedCoin.defaultNetwork : selectedCoin.name}}
                      </a>
                    </div>

                    <!-- <a class="coin-links d-flex" href="{{selectedCoin.extraData?.blockExplorer}}" target="_blank">
                      <img src="/assets/chainex/images/icons/blockexplorer.svg" class="mr-2 coin-web image-container"
                        loading="lazy">
                      <span class="span-size">Block Explorer</span>
                    </a> -->
                  </ng-template>
                </div>
              </div>
            </div>
          <!-- </div> -->
          </ng-container>

          <!-- </div> -->

        <!-- social media icons -->
        <div class="col-12 col-xl-11 pl-2">
          <div class="row my-3"> <!-- social-media -->
              <div class="col-auto pr-0">
                <div class="coin-web mr-2">

                </div>
              </div>
              <div class="col px-0">
              <div class="row mx-0">
              <div class="col-4 pl-0 pr-2 px-xl-3 my-1" *ngIf="selectedCoin.extraData?.instagramLink">
                <a class="coin-links" href="{{selectedCoin.extraData?.instagramLink}}" target="_blank">
                  <img src="/assets/chainex/images/icons/instagram.svg" class="image-container"
                    loading="lazy">
                </a>
              </div>
              <div class="col-4 pl-0 pr-2 px-xl-3 my-1" *ngIf="selectedCoin.extraData?.facebookLink">
                <a class="coin-links" href="{{selectedCoin.extraData?.facebookLink}}" target="_blank">
                  <img src="/assets/chainex/images/icons/facebook.svg" class="image-container"
                    loading="lazy">
                </a>
              </div>
              <div class="col-4 pl-0 pr-2 px-xl-3 my-1" *ngIf="selectedCoin.extraData?.twitterLink">
                <a class="coin-links" href="{{selectedCoin.extraData?.twitterLink}}" target="_blank">
                  <img src="/assets/chainex/images/icons/twitter.svg" class="image-container"
                    loading="lazy">
                </a>
              </div>
              <div class="col-4 pl-0 pr-2 px-xl-3 my-1" *ngIf="selectedCoin.extraData?.redditLink">
                <a class="coin-links" href="{{selectedCoin.extraData?.redditLink}}" target="_blank">
                  <img src="/assets/chainex/images/icons/reddit_orange.svg" class="image-container"
                    loading="lazy">
                </a>
              </div>
              <div class="col-4 pl-0 pr-2 px-xl-3 my-1" *ngIf="selectedCoin.extraData?.telegramLink">
                <a class="coin-links" href="{{selectedCoin.extraData?.telegramLink}}" target="_blank">
                  <img src="/assets/chainex/images/icons/telegram_orange.svg" class="image-container"
                    loading="lazy">
                </a>
              </div>
              <div class="col-4 pl-0 pr-2 px-xl-3 my-1" *ngIf="selectedCoin.extraData?.youtubeLink">
                <a class="coin-links" href="{{selectedCoin.extraData?.youtubeLink}}" target="_blank">
                  <img src="/assets/chainex/images/icons/youtube.svg" class="image-container"
                    loading="lazy">
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #supportedCoins>
    <div class="row mx-0">
      <div class="col-12 col-xl-10 mx-auto container-px">
        <h2 class="text-center pb-2">Our supported <span class="orange-text"><strong>coins</strong></span></h2>
        <p class="text-center h5 pb-1">Learn more about our coins by selecting one below:</p>
        <div class="d-flex justify-content-center has-search py-3">
          <div class="input-group border-1 col-lg-4 col-xl-3 col-md-4 col-sm-6 col-8 search-input rounded" [ngClass]="darkTheme ? 'search-input-dark' : null">
            <input type="text" (keyup)="applyFilter($event.target.value)"
            class="form-control border-0 px-2" placeholder="Search for a coin"  [ngClass]="darkTheme ? 'form-control-dark' : null">
            <mat-icon class="search-icon" [ngClass]="darkTheme ? 'search-icon-dark' : null">search</mat-icon>
          </div>
        </div>
        <div class="d-flex justify-content-center pb-5">
          <div class="container row py-3 mx-0 d-flex justify-content-center container-px">
            <div *ngFor="let coin of filteredCoinCards" class="py-4 px-1 d-flex justify-content-center">
              <a class="mat-button" [routerLink]="['/coins/' + coin.code.toLowerCase()]">
                <div [ngClass]="darkTheme ? 'dark-box dark-coin-shadow' : 'light-box'" class="d-block flex-column mx-auto coin-card pt-4">
                  <div class="col-12 coin-card-head">
                    <img class="coin-card-icon mr-2" [src]="imageUrl + coin.code.toLowerCase() + '.png'" (error)="$event.target.src = imageUrl + 'fallback_coin_icon.png'">
                    <strong class="coin-card-header">{{coin.code}}</strong> <br />
                    <div class="d-flex flex-row">{{coin.name}}</div>
                  </div>
                  <div *ngFor="let Coinmarket of coinCardMarkets[coin.id] | slice: 0:3;" class="col-12 d-flex text-left px-3 card-market">
                    <span>{{Coinmarket.coin}}/{{Coinmarket.exchange}}:</span> <span>{{Coinmarket.lastPrice | number: '1.2-2'}}</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 mx-auto d-flex justify-content-center">
          <a class="text-center mat-raised-button mat-primary orange-gradient py-2 mb-3 px-0 w-100 btn"
          [routerLink]="loggedIn ? ['/markets'] : ['/register']">START TRADING TODAY!</a>
        </div>
      </div>
    </div>
  </ng-template>

</div>
