import { SessionStorageService } from '../core/services/session-storage.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionLike } from 'rxjs';
import { HomeService } from './home.service';
import { UtilHelper } from '../core/helpers/util-helper';
import { MarketsService, IMarket } from '../markets/markets.service';
import { IMarketData } from '../core/interfaces/home-interface';
import { QuickDialogSetting } from '../core/interfaces/quickbuysell';
import { StoreService } from '../core/services/store.service';
import { QuickWizardComponent } from '../markets/order-types/quick-wizard/quick-wizard.component';
import { FormControl } from '@angular/forms';
import { CommentCard } from './../core/interfaces/commentCard';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  private subs: SubscriptionLike[] = [];
  private marketUpdateSub: SubscriptionLike;
  public valueChange: Function = UtilHelper.bigValueToFixed;

  @ViewChild(MatSort) sort: MatSort;

  tableDefinition: string[] = [
    'marketPair',
    'coinName',
    'lastPrice',
    'change',
    'high',
    'low',
    'volume'
  ];

  userComments: CommentCard[] = [
    {
      userName: 'Michael Bernardt',
      comment: `ChainEX offers users a great user experience and interface. ` +
      `Their fees are also the best in the market! ` +
      `They offer a great referral program as well for those who'd like to make an extra income!`,
      image: '../../assets/chainex/images/pages/home/mbernardt_cropped.webp'
    },
    {
      userName: 'Tanaka Chanisa',
      comment: `ChainEX offers great support! My ticket was solved in less than an hour. ` +
      `I love how easy their platform is and the incentives on offer through the referral program!`,
      image: '../../assets/chainex/images/pages/home/tchanisa_cropped.webp'
    },
    {
      userName: 'SJ Pretorius',
      comment: `I started using ChainEX, on recommendation of a friend. ` +
      `What immediately attracted me to the exchange is it's a South African entity; ` +
      `it offers attractively low transaction fees! ` +
      `Plus the support is on another level! The team is always there to assist!`,
      image: '../../assets/chainex/images/pages/home/sjpretorius_cropped.webp'
    }
    ];

  utilHelper: UtilHelper = UtilHelper;

  filterControl: FormControl = new FormControl('');
  filterCoin: string = '1';
  filterSearch: string = '';

  announcements: any[];

  coinData: MatTableDataSource<IMarket> = new MatTableDataSource([]);

  chartFirstLoad: boolean = true;
  chartResting: boolean = false;

  coinLabels: string[] = [];
  coinPrices: IMarketData[] = [];
  coinSelected: string = '';

  decimalSpaces: number = 8;
  darkTheme: boolean = false;

  environment: any = environment.config;

  graphPoints: Array<any> = [];

  markets: {
    coin: string,
    coinName: string,
    exchange: string,
    exchangeName: string,
    id: string,
    icon: string,
    lastPrice: string,
    coinDecimals: number,
    exchangeDecimals: number
  }[] = [];

  orderBookArray: any = [];
  orderBookTime: number;

  setting: QuickDialogSetting = {
    type: 'buy',
    coin: 'ZAR',
    exchange: 'BTC'
  };

  selectedMarket: any;
  siteName: String = environment.config.EXCHANGE_NAME_L;
  unreadAnnouncements: boolean = false;
  assetsPath: string = '/assets/chainex/images/pages/home/';

  constructor(
    private homeService: HomeService,
    private sessionStorage: SessionStorageService,
    public marketService: MarketsService,
    private store: StoreService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.markets = [];
    this.orderBookTime = Date.now();
    // fetch markets only once on init

    // subscribe for updates
    this.subs.push(this.marketService.marketUpdateSubject.subscribe((response) => {
      this.setCoinLabels(response);
      this.createPairList(response);
      this.generateMarketStats();
    }));

    // subscribe to announcements
    this.subs.push(this.store.subscribe('announcements').subscribe((response: any) => {
      if (!response.refresh) {
        const temp: any[] = [];
        for (let i = 0; i < response.data.length; i++) {
          if (!!response.data[i].active && response.data[i].active === '1') {
            temp.push(response.data[i]);
          }
        }
        this.announcements = temp;
        this.getUnreadAnnouncements();
      }
    }));

    this.coinData.filterPredicate = function (data: IMarket, filters: string) {
      const filterJson = JSON.parse(filters);
      if (filterJson.exchangeId !== '-1') {
        return filterJson.exchangeId === data.exchangeId
          && (data.exchangeCode.toLowerCase().indexOf(filterJson.search) > -1
            || data.coinCode.toLowerCase().indexOf(filterJson.search) > -1 || filterJson.search === '');
      } else {
        return (data.exchangeCode.toLowerCase().indexOf(filterJson.search) > -1
          || data.coinCode.toLowerCase().indexOf(filterJson.search) > -1 || filterJson.search === '');
      }
    };

    this.subs.push(this.sessionStorage.observe('THEME').subscribe(
      (data) => {
        this.darkTheme = data === 'dark-theme';
      }
    ));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    if (this.marketUpdateSub) {
      this.marketUpdateSub.unsubscribe();
    }
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  generateMarketStats() {
    this.marketService.getMarketPairsWithStats().subscribe(response => {
      const stats: IMarketData[] = [];
      let normStat: IMarketData = null;
      let maxChange: IMarketData = null;
      let minChange: IMarketData = null;
      let maxVol: IMarketData = null;

      for (let i = 0; i < response.data.length; i++) {
        const market = response.data[i];
        if (market.marketPair === 'BTC/ZAR' || market.marketPair === 'BTC/USDT') {
          normStat = { ...market };
          normStat.type = '';
          normStat.text = '';
          stats.push(normStat);
        }

        if (i > 0) {
          if (market.marketVolumeInBTC > maxVol.marketVolumeInBTC) {
            maxVol = { ...market };
            maxVol.type = 'maxVol';
          }
          if (UtilHelper.strToNumber(market.change) > UtilHelper.strToNumber(maxChange.change)) {
            maxChange = { ...market };
            maxChange.type = 'maxChange';
          }
          if (UtilHelper.strToNumber(market.change) < UtilHelper.strToNumber(minChange.change)) {
            minChange = { ...market };
            minChange.type = 'minChange';
          }
        } else {
          maxVol = { ...market };
          maxVol.type = 'maxVol';

          maxChange = { ...market };
          maxChange.type = 'maxChange';

          minChange = { ...market };
          minChange.type = 'minChange';
        }
        if (response.data.length - 1 === i) {
          stats.push(maxChange);
          stats.push(minChange);
          stats.push(maxVol);
          if (this.coinPrices.length === 0) {
            this.coinPrices = stats;
          } else {
            for (let a = 0; a < stats.length; a++) {
              this.coinPrices[a].takerFee = stats[a].takerFee;
              this.coinPrices[a].change = stats[a].change;
              this.coinPrices[a].changeColor = stats[a].changeColor;
              this.coinPrices[a].coinCode = stats[a].coinCode;
              this.coinPrices[a].coinId = stats[a].coinId;
              this.coinPrices[a].coinName = stats[a].coinName;
              this.coinPrices[a].exchangeCode = stats[a].exchangeCode;
              this.coinPrices[a].exchangeId = stats[a].exchangeId;
              this.coinPrices[a].exchangeName = stats[a].exchangeName;
              this.coinPrices[a].high = stats[a].high;
              this.coinPrices[a].id = stats[a].id;
              this.coinPrices[a].lastPrice = stats[a].lastPrice;
              this.coinPrices[a].low = stats[a].low;
              this.coinPrices[a].marketPair = stats[a].marketPair;
              this.coinPrices[a].makerFee = stats[a].makerFee;
              this.coinPrices[a].topAsk = stats[a].topAsk;
              this.coinPrices[a].topBid = stats[a].topBid;
              this.coinPrices[a].volume = stats[a].volume;
              this.coinPrices[a].btcEstimate = stats[a].btcEstimate;
              this.coinPrices[a].yesterdayPrice = stats[a].yesterdayPrice;
              this.coinPrices[a].type = stats[a].type;
              this.coinPrices[a].text = stats[a].text;
            }
          }
          this.generateChartData();
        }
      }
    });
  }

  public getUnreadAnnouncements() {
    this.unreadAnnouncements = false;
    this.announcements.forEach((item: any) => {
      if (item.read !== '1') { this.unreadAnnouncements = true; }
    });
  }

  public announcementsRead() {
    this.store.setAnnouncementsRead();
  }

  marketExist(coin: string, exchange: string) {
    const count: number = this.markets.filter(obj => obj.coin === coin && obj.exchange === exchange).length;
    return count === 1 ? true : false;
  }

  scrollToTop() {
    const element = document.getElementById('page-top');
    element.scrollIntoView({behavior: 'smooth'});
  }

  setCoinLabels(response: IMarket[]) {
    this.coinLabels = [];
    for (let i = 0; i < response.length; i++) {
      const filteredLabels = this.coinLabels.filter(searchLabel => searchLabel === response[i].exchangeCode);
      if (filteredLabels.length === 0) {
        this.coinLabels.push(response[i].exchangeCode);
      }
    }
  }

  isInPairs(name: string, pairs: IMarket[]) {
    for (let i = 0; i < pairs.length; i++) {
      if (pairs[i].exchangeName === name) {
        return true;
      }
    }
    return false;
  }

  createPairList(pairs: IMarket[]) {
    this.coinData.data = pairs;
  }

  generateChartData() {
    if (this.chartResting) {
      return;
    }

    this.chartResting = true;
    const self = this;
    setTimeout(function () {
      self.chartResting = false;
    }, 30000);

    let chartPair: string;
    const oneWeek: number = 604800;
    const endDate: number = Date.now() / 1000;
    const startDate: number = (endDate - oneWeek);
    const temp: number = this.coinPrices.length;
    const points: any = { pair: '', data: [0, 0, 0, 0, 0, 0, 0] };

    for (let i = 0; i < this.coinPrices.length; i++) {
      chartPair = this.coinPrices[i].marketPair;
      if (this.graphPoints.length === 0) {
        this.coinPrices[i].chartData = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '' }];
      } else {
        for (let cp = 0; cp < this.graphPoints.length; cp++) {
          if (this.graphPoints[cp].pair === chartPair) {
            this.coinPrices[cp].chartData = [{ data: this.graphPoints[cp].data, label: '' }];
          }
        }
      }
      this.homeService.getChartData({
        symbol: chartPair, resolution: '1D',
        from: startDate, to: endDate
      }).subscribe((response) => {
        if (!!response.c) {
          this.coinPrices[i].chartData = [{ data: response.c, label: '' }];
          points.pair = chartPair;
          points.data = response.c;
          this.graphPoints = [];
          this.graphPoints.push(points);
        } else {
          this.coinPrices[i].chartData = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '' }];
        }
      });
      if (i === temp - 1) {
        this.coinPrices = this.coinPrices;
        this.generateChartData();
      }
    }
  }

  changeTheme(theme: string) {
    this.sessionStorage.store('THEME', theme);
  }

  openDialog(type: number = 0): void {
    try {
      this.dialog.open(QuickWizardComponent, {
        width: '500px',
        position: { top: '52px', right: '25px'},
        data: {type}
      });
    } catch (e) {
      console.log(e);
    }
  }

  // BurnX methods
  filter() {
    this.coinData.filter = JSON.stringify({ exchangeId: this.filterCoin, search: this.filterSearch.toLowerCase() });
  }

  pairChange(e: any) {
    const pair = this.marketService.getMarketFromExchangeCode(this.coinLabels[e.index]);
    if (pair !== null) {
      this.filterCoin = pair.exchangeId;
    } else {
      this.filterCoin = '-2';
    }
    this.filter();
  }
}
