<div class="row mx-0 px-2 mt-2">
  <h2 [ngClass]="!darkTheme ? 'orange-text' : 'white-text'" class="col-12 px-0 px-lg-3 my-4 bold-text">START TRADING TODAY</h2>
  <div class="col-12">
    <div class="row">
      <div class="col-9 pr-0">
        <input class="home-inputs w-100 pl-2 py-2" placeholder="I want to spend"
          type="number" step="0.00000001" [formControl]="exchangeAmountControl" />
      </div>
      <div class="col-3 pl-0">
        <ng-container *ngIf="multiMarketSelection; else singleMarketSelection">
          <mat-select class="yellow-button w-100 mat-dropdown-box buy-now-button" [formControl]="exchangeSelectionControl">
            <mat-option *ngFor="let exchange of exchanges" [value]="exchange">
              {{ exchange }}
            </mat-option>
          </mat-select>
        </ng-container>
        <ng-template #singleMarketSelection>
          <button  class="yellow-button w-100 mat-dropdown-box buy-now-button border-0 text-left">
            {{activeMarket.exchangeCode}}
          </button>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="col-12">
    <p id="price-converter-label" class="ml-3 my-0 pl-2 py-2">
      <span *ngIf="activeMarket.id > 0">{{activeMarket.pairLabel}} {{activeMarket.lastPrice | decimalNumber: activeMarket.exchangeDecimals}}</span>
    </p>
  </div>

  <div class="col-12">
    <div class="row">
      <div class="col-9 pr-0">
        <input class="home-inputs w-100 pl-2 py-2" placeholder="You will receive"
          type="number" step="0.00000001" [formControl]="coinAmountControl" />
      </div>
      <div class="col-3 pl-0">
        <ng-container *ngIf="multiMarketSelection; else singleCoinSelection">
          <mat-select class="yellow-button w-100 mat-dropdown-box buy-now-button" [formControl]="coinSelectionControl">
            <mat-option *ngFor="let coin of coins" [value]="coin">
              {{ coin }}
            </mat-option>
          </mat-select>
        </ng-container>
        <ng-template #singleCoinSelection>
          <button class="yellow-button w-100 mat-dropdown-box buy-now-button border-0 text-left">
            {{activeMarket.coinCode}}
          </button>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="col-12 mt-2">
    <a routerLink="/register" class="buy-now-button mat-raised-button orange-gradient w-100 main-button py-2 my-4" i18n>
      BUY NOW!
    </a>
  </div>
</div>
