import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'home-image-tile',
  templateUrl: './image-tile.component.html',
  styleUrls: ['./image-tile.component.css']
})
export class ImageTileComponent implements OnInit {
  @Input() image: any;
  @Input() text: any;
  @Input() layout: string = 'col'; // can be 'col' or 'row'
  @Input() darkTheme: boolean = false;
  @Input() link: string = '';

  lightImagePath: string = '../../../assets/chainex/images/pages/home/light-mode/';
  darkImagePath: string = '../../../assets/chainex/images/pages/home/dark-mode/';

  constructor() { }

  ngOnInit() {
    // TODO check for theme, then change imagePath
  }

}
