import { distinctUntilChanged, tap } from 'rxjs/operators';
import { MarketsService, IMarket } from 'src/app/markets/markets.service';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfoService } from 'src/app/info/info.service';
import { MatDialog } from '@angular/material/dialog';
import { QuickWizardComponent } from 'src/app/markets/order-types/quick-wizard/quick-wizard.component';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { StoreService } from 'src/app/core/services/store.service';
import { FormControl, Validators } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ReferralService } from 'src/app/referral/referral.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DecimalNumberPipe } from 'src/app/core/pipe/decimalNumber.pipe';
import { QuickDialogSetting } from 'src/app/core/interfaces/quickbuysell';
import { QuickOrderDialogComponent } from 'src/app/markets/order-types/quick-order-dialog/quick-order-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  imageUrl: string = 'assets/images/coins/';

  @Input() darkTheme: boolean = false;
  @Input() coinCode: string = 'ZAR';

  infoService: InfoService;
  environment: any = environment.config;
  address: typeof environment.config.ADDRESS_DETAILS = environment.config.ADDRESS_DETAILS;
  exchangeName: string = environment.config.EXCHANGE_NAME_L;
  appURL: string = environment.config.APP_URL;

  subs: any = [];
  summaryData: MatTableDataSource<any> = new MatTableDataSource([]);

  selectedDateFrom: Date;
  selectedDateTo: Date;
  referralCode: string = '';
  referralUrl: string = '';
  referralEmail: string;
  limitReached: boolean = false;
  emailError: boolean = false;
  emailErrorMessage: string = '';
  totalFiat: string;
  totalBTC: string;
  userFee: any;
  totalVolume: number = 0;
  selectedCoin: IMarket;
  coins: IMarket[];
  lastPrice: string;

  totalBtcControl: FormControl = new FormControl('', []);
  emailControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  dateFromControl: FormControl = new FormControl(['']);
  dateToControl: FormControl = new FormControl(['']);

  @ViewChild('coinCard', { read: ElementRef }) public coinCard: ElementRef<any>;
  @ViewChild('summarySort', { static: true }) summarySort: MatSort;
  @ViewChild('yourRefSort', { static: true }) refSort: MatSort;

  referralData: MatTableDataSource<any> = new MatTableDataSource([]);
  referralsummary: any = {
    month: {},
    total: {}
  };

  public scrollRight(): void {
    this.coinCard.nativeElement.scrollTo({ left: (this.coinCard.nativeElement.scrollLeft + 100), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.coinCard.nativeElement.scrollTo({ left: (this.coinCard.nativeElement.scrollLeft - 100), behavior: 'smooth' });
  }

  constructor(
    _infoService: InfoService,
    private i18n: I18n,
    private store: StoreService,
    private dialog: MatDialog,
    private clipboard: ClipboardService,
    private marketsService: MarketsService,
    private sessionStorage: SessionStorageService,
    private referralService: ReferralService,
    public snackbar: MatSnackBar,
    public decimalNumber: DecimalNumberPipe,
  ) {
    this.infoService = _infoService;
  }

  ngOnInit() {
    this.subs.push(
      this.sessionStorage.observe('THEME')
        .subscribe(data => this.darkTheme = data === 'dark-theme')
    );

    this.loadCoinBalance();

    const filterMarkets = (allMarkets: IMarket[]) => {
      const markets: IMarket[] = [];

      for (const market of allMarkets) {
        const index = markets.findIndex(oneMarket => oneMarket.coinCode === market.coinCode);

        if (index >= 0 && market.exchangeCode === 'ZAR') {
          markets[index] = market;
        } else if (index === -1) {
          markets.push(market);
        }
      }

      return markets.sort((market1, market2) => market2?.marketVolumeInBTC - market1?.marketVolumeInBTC);
    };

    this.subs.push(
      this.marketsService.marketUpdateSubject.pipe(
        distinctUntilChanged(),
        tap((response: IMarket[]) => {
          this.coins = filterMarkets(response);
        })
      ).subscribe()
    );

    this.subs.push(this.store.subscribe('settings').subscribe((response) => {
      if (!!response.data && !!response.data.referral_code) {
        this.referralCode = response.data.referral_code;
        this.referralUrl = `${this.appURL}/register/${this.referralCode}`;
      }
      if (!!response.referral_code) {
        this.referralCode = response.referral_code;
        this.referralUrl = `${this.appURL}/register/${this.referralCode}`;
      }
    }));

    this.summaryData.sort = this.summarySort;
    this.referralData.sort = this.refSort;
    this.dateFromControl.disable();
    this.dateToControl.disable();

    const promises = [
      new Promise((resolve) => this.getUserFee(resolve)),
      new Promise((resolve) => this.getReferredUsersInfo(resolve))
    ];
    Promise.all(promises).then(() => { });
    this.selectedDateFrom = this.getThisMonthStart();
    this.selectedDateTo = (new Date());
    this.getReferralSummary();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  selectCoin(coin: any) {
    this.selectedCoin = coin;
    this.setLastPrice();
  }

  setLastPrice() {
    if (this.selectedCoin) {
      const value = this.decimalNumber.transform(
        this.selectedCoin.lastPrice,
        this.selectedCoin.exchangeDecimals,
        this.selectedCoin.coinCode
      );

      if (this.selectedCoin.exchangeCode === 'ZAR') {
        this.lastPrice = `R ${value}`;
      } else {
        this.lastPrice = `${value} ${this.selectedCoin.exchangeCode}`;
      }
    }
  }

  loadCoinBalance() {
    this.subs.push(this.store.subscribe('totalSummary').subscribe(response => {
      if (!response.refresh) {
        this.totalBTC = response.data.totalBitcoin + ' BTC';
        if (this.exchangeName === 'burnx') {
          this.totalFiat = `$ ${response.data.totalUSDT}`;
        } else {
          this.totalFiat = response.data.zarFiatType ? `R ${response.data.totalZAR}` :  `${response.data.totalUSDT} USDT`;
        }
      }
    }));
  }

  openQuickDialog(type: number) {
    if (this.selectedCoin) {
      const data: QuickDialogSetting = {
        coin: this.selectedCoin.coinCode,
        exchange: this.selectedCoin.exchangeCode,
        type: (type === 1 ? 'sell' : 'buy')
      };
      this.dialog.open(QuickOrderDialogComponent, {
        width: '550px',
        data: data
      });
    } else {
      this.dialog.open(QuickWizardComponent, {
        width: '500px',
        maxHeight: '90vh',
        data: {type}
      });
    }
  }

  getThisMonthStart () {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setDate(1);
    return date;
  }

  getReferralSummary() {
    const data = {
      dateFrom: this.selectedDateFrom === null ? '' : new Date(this.selectedDateFrom).toUTCString(),
      dateTo: this.selectedDateTo === null ? '' : new Date(this.selectedDateTo).toUTCString(),
    };
    this.referralService.getReferralSummary(data).subscribe((response) => {
      if (response.response === 'success') {
        this.summaryData.data = response.data;
        this.totalBtcControl.setValue(this.decimalNumber.transform(response.total_btc, 8, 'btc'));
      }
    });
  }

  emailInvite(email: string) {
    this.emailControl.markAsTouched();
    if (this.emailControl.valid) {
      if (environment.config.EXCHANGE_NAME_L === 'chainex') {
        window.open(`mailto:` + this.referralEmail + `?&subject=Have you heard of ChainEX?&body=
        Hi there,\%0D%0A\%0D%0A
        There’s this opportunity I’d love to share with you. I trade, buy and sell cryptocurrencies to make a \
        profit on ChainEX and would like to invite you to sign up using my referral link!\%0D%0A\%0D%0A
        ChainEX is a South African cryptocurrency exchange that offers us a platform to make a profit through buying, \
        selling, and trading over 20 digital assets like Bitcoin. Don’t wanna trade? That’s fine, you can \
        share your referral link and make an income anyway!\%0D%0A\%0D%0A
        What are you waiting for? From as little as R10 you can start using ChainEX!\%0D%0A\%0D%0A
        Get started and sign up now with my referral link: ${this.referralUrl} \%0D%0A\%0D%0A
        Learn more about how to make an extra income yourself here \
        https://blog.chainex.io/more-friends-more-crypto/`, '_blank');
      } else {
        // TODO: Burnex email goes here
      }
      this.referralEmail = '';
      this.emailControl.markAsUntouched();
    }
  }

  emailKeyPress(event: KeyboardEvent) {
    if ((event.key || event.keyCode) === 13 && !this.limitReached) {
      this.emailInvite(this.referralEmail);
    }
  }

  getUserFee(resolve: any) {
    this.referralService.getUserFee()
      .subscribe((response: any) => {
        if ((response.response === 'success') && (response.data != null)) {
          this.userFee = response.data;
          this.totalVolume = Number(this.userFee.total_volume);
          this.totalVolume = isNaN(this.totalVolume) ? 0 : this.totalVolume;
        } else {
          this.totalVolume =  0;
        }
        resolve();
      });
  }

  getReferredUsersInfo(resolve: any) {
    this.referralService.getReferredUsersInfo()
      .subscribe((response: any) => {
        if ((response.response === 'success') && (response.data != null)) {
          this.referralData.data = response.data.referralInfo;
          this.referralsummary.month = response.data.month;
          this.referralsummary.total = response.data.total;
        }
        resolve();
      });
  }

  copyToClipboard(str: string) {
    this.clipboard.copyFromContent(str);
    this.snackbar.open(this.i18n('Copied') + ': ' + str, this.i18n('Close'), {duration: 2000});
  }
}
