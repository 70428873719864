<div id="page-top"></div>
<app-header></app-header>

<div class="row container-fluid mx-0 px-0" [ngClass]="darkTheme ? 'dark-theme-background' : 'light-theme-background'">
  <div class="header-map d-flex justify-content-center w-100" [ngClass]="darkTheme ? 'white-text' : 'dark-text'">
    <div class="col-12 col-lg-11 col-xl-10 px-0 px-lg-1 my-auto">
      <div class="header-vertical-spacer mb-4 d-none d-lg-block"></div>
      <div class="header-container row mx-auto py-4 py-lg-3 mb-0 mb-lg-5 mb-lg-0 align-items-start justify-content-around">
        <div class="col-11 col-md-10 col-lg-6 px-0 my-3 my-lg-auto">
          <div class="col-12 col-md-10 px-0 mx-auto header-title">
            <h2 class="col-12 col-md-11 px-0 text-center text-lg-left pb-5" i18n>
              Welcome to the most <span class="orange-text">affordable</span> exchange in Africa
            </h2>

            <h4 class="col-12 px-0 text-center text-lg-left secondary-text" i18n>
              The future of money and investing is here. <a routerLink="/register" class="orange-yellow">JOIN</a>
              ChainEX now and be part of that future, TODAY!
            </h4>
          </div>

          <div class="col-9 mx-5 px-0 pt-5">
            <div class="col-12 row mx-auto px-0 justify-content-center justify-content-sm-between">
              <a routerLink="/register" class="mat-raised-button mat-primary orange-gradient col-12 col-sm-5 py-2 main-button" i18n>
                SIGN UP
              </a>

              <a routerLink="/login" class="mat-raised-button mat-primary orange-gradient col-12 col-sm-5 py-2 main-button" i18n>
                SIGN IN
              </a>
            </div>
          </div>
        </div>

        <div class="col-11 col-md-10 col-lg-5 mt-lg-0 mt-3 px-0">
          <div class="d-flex flex-column justify-content-center justify-content-lg-end">
            <div class="d-flex d-sm-none col-11 mx-auto px-0 mb-4">
              <div class="col-12 px-0 d-flex flex-row justify-content-between">
                <ng-container *ngTemplateOutlet="downloadApp"></ng-container>
              </div>
            </div>

            <img
              class="dark-stats-shadow phone"
              alt="IOS app coming soon"
              src="{{assetsPath}}iphone.png"
            >

            <div class="d-none d-sm-flex justify-content-center mt-3 flex-row px-0 w-100">
              <ng-container *ngTemplateOutlet="downloadApp"></ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mx-auto">
        <div class="col-12 mt-0 mt-sm-3 pt-3">
          <h2 class="text-center my-4" i18n>Our top traded <span class="orange-text"><b>markets</b></span></h2>
          <h4 class="text-center mb-3" i18n>Buy and Sell Bitcoin, Ethereum & other altcoins with ease on ChainEX.</h4>
        </div>
      </div>
    </div>
  </div>

  <div class="blueprint-background">
    <div class="col-12 mx-auto">
      <div class="col-12 my-4 my-lg-5">
        <div class="row d-flex justify-content-center">
          <div [ngClass]="darkTheme ? 'dark-stats-shadow' : 'stats-shadow'" *ngFor="let price of coinPrices"
            class="col-11 col-sm-8 col-md-5 col-lg-3 col-xl mb-3 mx-3 py-2 px-0 clickable market-card-container"
          >
            <home-market-card [market]="price" [darkTheme]="darkTheme"></home-market-card>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-3 mb-3 mx-auto">
        <a routerLink="/markets" class="mat-raised-button mat-primary orange-gradient w-100 py-2 main-button" i18n>
          SEE ALL MARKETS
        </a>
      </div>

      <div class="vertical-spacer d-none d-lg-block"></div>
    </div>

    <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="col-12 px-0">
      <div class="col-12 col-lg-9 mx-auto py-3">
        <h2 class="text-center my-4" i18n>
          <span class="orange-text"><b>Cryptocurrency</b></span> Trading Made Simple
        </h2>

        <h4 class="text-center mb-3" i18n>
          Buying and Selling your favourite cryptocurrency does not need to be a hassle.<br>
          With <b>ChainEX</b> you can securely start investing instantly in<br>
          <span class="emphasis-heading"><span class="orange-text">three</span> easy steps!</span>
        </h4>

        <div class="row mt-5 mb-0 mb-lg-5">
          <div class="col-12 col-lg-4 mb-5 mb-lg-0 d-flex flex-column align-items-center">
            <div class="row">
              <img
                class="mb-auto mr-3 mr-lg-0 steps-number-offset" loading="lazy"
                src="{{assetsPath}}light-mode/yellow_1.svg"
              >
              <img
                *ngIf="!darkTheme" class="mt-auto pt-4" loading="lazy"
                src="{{assetsPath}}light-mode/sign_up.svg"
              >
              <img
                *ngIf="darkTheme" class="mt-auto pt-4" loading="lazy"
                src="{{assetsPath}}dark-mode/sign_up.svg"
              >
            </div>

            <h4>SIGN UP</h4>
            <h4 class="text-center">Register and Verify</h4>
          </div>

          <img
            class="arrow-offset d-none d-lg-block"
            src="{{assetsPath}}light-mode/arrow.svg"
          >

          <div class="col-12 col-lg-4 mb-5 mb-lg-0 d-flex flex-column align-items-center">
            <div class="row">
              <img
                class="mb-auto mr-3 mr-lg-0 steps-number-offset" loading="lazy"
                src="{{assetsPath}}light-mode/yellow_2.svg"
              >
              <img
                *ngIf="!darkTheme" class="mt-auto pt-4" loading="lazy"
                src="{{assetsPath}}light-mode/deposit.svg"
              >
              <img *ngIf="darkTheme" class="mt-auto pt-4" loading="lazy"
                src="{{assetsPath}}dark-mode/deposit.svg"
              >
            </div>

            <h4>DEPOSIT</h4>
            <h4 class="text-center">EFT from your bank account</h4>
          </div>

          <img
            class="arrow-offset d-none d-lg-block"
            src="{{assetsPath}}light-mode/arrow.svg"
          >

          <div class="col-12 col-lg-4 mb-5 mb-lg-0 d-flex flex-column align-items-center arrow-background">
            <div class="row">
              <img
                class="mb-auto mr-3 mr-lg-0 steps-number-offset" loading="lazy"
                src="{{assetsPath}}light-mode/yellow_3.svg"
              >
              <img
                *ngIf="!darkTheme" class="mt-auto pt-4" loading="lazy"
                src="{{assetsPath}}light-mode/trade.svg"
              >
              <img *ngIf="darkTheme" class="mt-auto pt-4" loading="lazy"
                src="{{assetsPath}}dark-mode/trade.svg"
              >
            </div>

            <h4>TRADE</h4>
            <h4 class="text-center">Enter amount to spend and buy</h4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-9 mx-auto">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="col-12 mt-5 mt-lg-0 d-flex justify-content-center">
      <div class="row mx-0">
        <div class="col-12 col-lg-auto mx-auto mr-0">
          <img
            loading="lazy"
            *ngIf="!darkTheme"
            class="d-block w-100 mx-auto start-trading-lightbulb"
            src="{{assetsPath}}light-mode/start_trading_lightbulb.svg"
          >
          <img
            loading="lazy"
            *ngIf="darkTheme"
            class="d-block w-100 mx-auto"
            src="{{assetsPath}}dark-mode/start_trading_lightbulb.svg"
          >
        </div>

        <div class="col-12 col-lg d-flex justify-content-center left-overlap mr-auto">
          <div class="d-block pt-4 my-auto">
            <h2 class="text-center m-2" i18n>
              <span class="orange-text"><b>Trading crypto</b></span> with <b>ChainEX</b> is simple, right?
            </h2>

            <h4 class="text-center mt-4" i18n>
              Your favourite cryptocurrency exchange makes it easy for you to get involved in trading cryptocurrency!
            </h4>

            <h4 class="text-center mb-4" i18n>
              What are you waiting for? Start by buying as little as R10 in Bitcoin, Ethereum or your favourite altcoins!
            </h4>

            <div class="col-12 col-xl-6 pt-3 mx-auto">
              <a routerLink="/register" class="mat-raised-button mat-primary orange-gradient w-100 py-2 main-button" i18n>
                START TRADING TODAY
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 pt-5 mb-5 mb-lg-4 col-lg-9 map-background mx-auto">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <h2 class="text-center mb-4" i18n>Why <span class="orange-text"><b>invest</b></span> in digital assets?</h2>
    <div class="col-12 d-flex justify-content-center">
      <div class="col-12 col-lg-6 col-xl-5 d-inline-block">
        <home-image-tile [image]="'safe.svg'" [text]="'SAFE'" [layout]="'row'" [darkTheme]="darkTheme"></home-image-tile>
        <home-image-tile [image]="'easy_to_move.svg'" [text]="'EASY TO MOVE'" [layout]="'row'" [darkTheme]="darkTheme"></home-image-tile>
        <home-image-tile [image]="'store_of_value.svg'" [text]="'STORE OF VALUE'" [layout]="'row'" [darkTheme]="darkTheme"></home-image-tile>
        <home-image-tile [image]="'the_future.svg'" [text]="'IT\'S THE FUTURE'" [layout]="'row'" [darkTheme]="darkTheme"></home-image-tile>
        <home-image-tile [image]="'manipulated.svg'" [text]="'CANNOT BE MANIPULATED'" [layout]="'row'" [darkTheme]="darkTheme"> </home-image-tile>
      </div>
    </div>
    <div class="d-none d-lg-block vertical-spacer"></div>
  </div>

  <div class="col-12 col-lg-9 mx-auto">
    <div class="col-12 pt-5 pt-lg-0 mt-3 mt-lg-0 px-0 px-lg-5">
      <h2 class="text-center" i18n>What <span class="orange-text"><b>ChainEX</b></span> offers</h2>
      <div class="row d-flex justify-content-center">
        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile [image]="'over_20_coins.svg'" [text]="'OVER 20 COINS'" [darkTheme]="darkTheme">
          </home-image-tile>
        </div>

        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile [image]="'over_30_markets.svg'" [text]="'OVER 30 MARKETS'" [darkTheme]="darkTheme">
          </home-image-tile>
        </div>

        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile [image]="'partnership_program.svg'" [text]="'PARTNERSHIP PROGRAM'" [darkTheme]="darkTheme">
          </home-image-tile>
        </div>

        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile [image]="'scaling_fees.svg'" [text]="'SCALING FEES'" [darkTheme]="darkTheme">
          </home-image-tile>
        </div>

        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile [image]="'zar_trading.svg'" [text]="'ZAR TRADING'" [darkTheme]="darkTheme"></home-image-tile>
        </div>

        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile [image]="'simple_verification.svg'" [text]="'SIMPLE VERIFICATION'" [darkTheme]="darkTheme">
          </home-image-tile>
        </div>

        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile [image]="'dedicated_support.svg'" [text]="'DEDICATED SUPPORT'" [darkTheme]="darkTheme">
          </home-image-tile>
        </div>

        <div class="col-6 col-md-5 col-lg-4 col-xl-3 px-2 px-xl-3">
          <home-image-tile
            [image]="'multiple_order_types.svg'"
            [text]="'MULTIPLE ORDER&nbsp;TYPES'"
            [darkTheme]="darkTheme"
          >
          </home-image-tile>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-9 mx-auto">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <div class="row mb-5 mb-lg-0 d-flex justify-content-center">
      <div class="row mx-0 px-0">
        <img src="{{assetsPath}}light-mode/partner_with_a_friend.svg" loading="lazy">
        <div class="d-none d-sm-flex d-xl-none">
          <img class="mt-auto" *ngIf="!darkTheme" src="{{assetsPath}}light-mode/coins.svg">
          <img class="mt-auto" *ngIf="darkTheme" src="{{assetsPath}}dark-mode/coins.svg">
        </div>
      </div>
      <div class="col-12 col-xl-8 partner-text-overlap">
        <h2 class="text-center mb-4 mb-lg-0" i18n>Partner with us and <span class="orange-text"><b>earn</b></span></h2>
        <h4 class="text-center">Our referral program makes earning an extra passive income easy for you. Invite your friends and earn free Bitcoin for choosing and partnering with <b>ChainEX!</b></h4>
        <h4 class="text-center px-0 px-xl-3">
          The more you <span class="emphasis-heading">trade</span> and <span
            class="emphasis-heading orange-text">invite</span>,
          the more you <span class="emphasis-heading orange-text">earn!</span>
        </h4>
        <div class="col-12">
          <div class="col-12 col-lg-6 px-0 mt-5 mx-auto">
            <a routerLink="/register" class="mat-raised-button orange-gradient mat-primary w-100 py-2 main-button" i18n>
              START TRADING TODAY
            </a>
          </div>
        </div>
      </div>
      <div class="col-2 d-none d-xl-flex">
        <img class="partner-coins" *ngIf="!darkTheme" src="{{assetsPath}}light-mode/coins.svg">
        <img class="partner-coins" *ngIf="darkTheme" src="{{assetsPath}}dark-mode/coins.svg">
      </div>
    </div>
    <div class="vertical-spacer d-none d-lg-block"></div>
  </div>

  <div class="blueprint-background-flipped">
  <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="col-12 px-0">
    <div class="col-12 col-lg-9 mx-auto">
      <h2 class="text-center pb-4 pt-5">Still not <span class="orange-text"><b>convinced?</b></span></h2>
      <h4 class="text-center my-4">Still have questions? Need to get into contact?</h4>
      <div class="col-12 py-4 d-flex justify-content-center">
        <div class="col 12 col-lg-7 col-xl-6 d-inline-block">
          <home-image-tile [image]="'knowledge_base.svg'" [text]="'READ OUR KNOWLEDGE BASE'" [layout]="'row'" [link]="'https://support.chainex.io/kb/index.php'"
            [darkTheme]="darkTheme">
          </home-image-tile>
          <home-image-tile [image]="'support_portal.svg'" [text]="'CONTACT OUR SUPPORT PORTAL'" [layout]="'row'" [link]="'https://support.chainex.io/open.php'"
            [darkTheme]="darkTheme">
          </home-image-tile>
          <home-image-tile [image]="'join_telegram.svg'" [text]="'JOIN OUR TELEGRAM'" [layout]="'row'" [link]="'https://t.me/chainexchat'"
            [darkTheme]="darkTheme">
          </home-image-tile>
          <home-image-tile [image]="'ghost_lg.svg'" [text]="'READ OUR BLOG'" [layout]="'row'" [link]="'https://blog.chainex.io/'"
            [darkTheme]="darkTheme"></home-image-tile>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-9 py-2 mx-auto">
    <div class="vertical-spacer d-none d-lg-block"></div>
    <h2 class="text-center pt-5 pt-lg-0 my-4">Trade <span class="orange-text"><b>anywhere</b></span></h2>
    <div class="row mb-5 mb-lg-0 d-flex justify-content-center align-items-end">
      <div class="col-12 col-lg-3 col-xl-2 px-0">
        <img *ngIf="!darkTheme" class="d-block mx-auto"
          src="{{assetsPath}}light-mode/desktop.svg">
        <img *ngIf="darkTheme" class="d-block mx-auto"
          src="{{assetsPath}}dark-mode/desktop.svg">
        <h4 class="mt-3 mt-lg-0 mb-4 mb-lg-0 text-center">Desktop</h4>
      </div>

      <div class="col-12 col-lg-3 col-xl-2 px-0">
        <img *ngIf="!darkTheme" class="d-block mx-auto"
          src="{{assetsPath}}light-mode/mobile.svg">
        <img *ngIf="darkTheme" class="d-block mx-auto"
          src="{{assetsPath}}dark-mode/mobile.svg">
        <h4 class="mt-3 mt-lg-0 mb-4 mb-lg-0 text-center">Mobile</h4>
      </div>

      <div class="col-12 col-lg-3 col-xl-2 px-0">
        <img *ngIf="!darkTheme" class="d-block mx-auto"
          src="{{assetsPath}}light-mode/api.svg">
        <img *ngIf="darkTheme" class="d-block mx-auto"
          src="{{assetsPath}}dark-mode/api.svg">
        <h4 class="mt-3 mt-lg-0 mb-4 mb-lg-0 text-center">API</h4>
      </div>
    </div>

    <div class="vertical-spacer d-none d-lg-block my-2"></div>
  </div>
  </div>

  <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="d-block d-xl-none col-12 px-0">
    <div class="col-12 col-lg-9 mx-auto">
      <h2 class="text-center my-5">What others <span class="orange-text"><b>Say</b></span></h2>

      <div>
        <div id="userCarousel" class="carousel carousel-fade" data-interval="false">
          <div class="carousel-item" *ngFor="let userComment of userComments" [ngClass]="{'active': userComment.userName === userComments[0].userName}">
            <shared-comment-card [userComment]="userComment"></shared-comment-card>
          </div>
        </div>
      </div>
    </div>

    <a class="carousel-control-prev" href="#userCarousel" role="button" data-slide="prev">
      <img class="d-block my-auto" src="{{assetsPath}}light-mode/left_triangle.svg">
    </a>

    <a class="carousel-control-next" href="#userCarousel" role="button" data-slide="next">
      <img class="d-block my-auto" src="{{assetsPath}}light-mode/right-triangle.svg">
    </a>
  </div>

  <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="d-none d-xl-block col-12 px-0">
    <div class="col-12 col-lg-9 mx-auto">
      <h2 class="text-center my-5">What others <span class="orange-text"><b>Say</b></span></h2>
      <div>
        <div id="userCarousel2" class="carousel carousel-fade" data-interval="false">
          <div class="carousel-item active">
            <div class="row d-flex flex-row justify-content-center">
              <div *ngFor="let userComment of userComments" class="col-3 px-2">
                <shared-comment-card [userComment]="userComment"></shared-comment-card>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <a class="carousel-control-prev" href="#userCarousel2" role="button" data-slide="prev">
      <img class="d-block my-auto" src="{{assetsPath}}light-mode/left_triangle.svg">
    </a>
    <a class="carousel-control-next" href="#userCarousel2" role="button" data-slide="next">
      <img class="d-block my-auto" src="{{assetsPath}}light-mode/right-triangle.svg">
    </a>
  </div>

  <div [ngClass]="darkTheme ? 'dark-primary-bg':'primary-bg'" class="col-12 px-0">
    <div class="vertical-spacer my-3 my-lg-5"></div>
  </div>

  <div class="col-12 col-lg-10 mx-auto">
    <h2 class="text-center pt-5 pt-lg-0 my-5">Our <span class="orange-text"><b>partners</b></span></h2>
    <div class="col-12">
      <div class="row justify-content-center pt-3 pb-3 pl-3 pr-0">
        <div class="col-xlg-2 col-lg-4 col-sm-6 col-12 my-3 d-flex justify-content-center align-items-center">
          <a href="https://www.okex.com/join/1939211" target="_blank">
            <picture *ngIf="!darkTheme">
              <img class="okex-height" src="/assets/{{siteName}}/images/pages/home/links/OKEX-lighttheme.png" alt="OKEX">
            </picture>
            <picture *ngIf="darkTheme">
              <img class="okex-height" src="/assets/{{siteName}}/images/pages/home/links/OKEX-darktheme.png" alt="OKEX">
            </picture>
          </a>
        </div>
        <div class="col-xlg-2 col-lg-4 col-sm-6 col-12 my-3 d-flex justify-content-center align-items-center">
          <a href="https://www.intotheblock.com/" target="_blank">
            <picture *ngIf="!darkTheme">
              <img class="okex-height" src="/assets/{{siteName}}/images/pages/home/links/intotheblock.png" alt="Into The Block">
            </picture>
            <picture *ngIf="darkTheme">
              <img class="okex-height" src="/assets/{{siteName}}/images/pages/home/links/intotheblock-darktheme.png" alt="Into The Block">
            </picture>
          </a>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row justify-content-around pt-3 pb-3 pl-3 pr-0">
        <div class="col-xlg-2 col-lg-4 col-sm-6 col-12 my-3 d-flex justify-content-center align-items-center">
          <a href="https://www.coingecko.com/en/exchanges/chainex" target="_blank">
            <picture *ngIf="!darkTheme">
              <img class="coinGecko partner-image" src="/assets/{{siteName}}/images/pages/home/links/coingecko-lighttheme.png" alt="coingecko">
            </picture>
            <picture *ngIf="darkTheme">
              <img class="coinGecko partner-image" src="/assets/{{siteName}}/images/pages/home/links/coingecko-darktheme.png" alt="coingecko">
            </picture>
          </a>
        </div>
        <div class="col-xlg-2 col-lg-4 col-sm-6 col-12 my-3 d-flex justify-content-center align-items-center">
          <a id="coinRanking" href="https://coinranking.com/exchange/QITcKSL0ixe/chainex" target="_blank">
            <mat-icon class="partner-image h-auto" svgIcon="coinranking" i18n-matTooltip matTooltip="Coin Ranking"></mat-icon>
          </a>
        </div>
        <div class="col-xlg-2 col-lg-4 col-sm-6 col-12 my-3 d-flex justify-content-center align-items-center">
          <a href="https://www.coinlore.com/exchange/chainex" target="_blank">
            <picture *ngIf="!darkTheme">
              <img class="partner-image" src="/assets/{{siteName}}/images/pages/home/links/coinlore-lighttheme.png" alt="coinlore">
            </picture>
            <picture *ngIf="darkTheme">
              <img class="partner-image" src="/assets/{{siteName}}/images/pages/home/links/coinlore-darktheme.png" alt="coinlore">
            </picture>
          </a>
        </div>
        <div class="col-xlg-2 col-lg-4 col-sm-6 col-12 my-3 d-flex justify-content-center align-items-center">
          <a href="https://coinstats.app/exchanges/chainex" target="_blank">
            <picture >
              <img *ngIf="!darkTheme" class="partner-image" src="/assets/{{siteName}}/images/pages/home/links/coinstats-lighttheme.png" alt="coinstats">
            </picture>
            <picture *ngIf="darkTheme">
              <img class="partner-image" src="/assets/{{siteName}}/images/pages/home/links/coinstats-darktheme.png" alt="coinstats">
            </picture>
          </a>
        </div>
        <div class="col-xlg-2 col-lg-4 col-sm-6 col-12 my-3 d-flex justify-content-center align-items-center">
          <a href="https://www.cryptowisser.com/exchange/chainex/" target="_blank">
            <picture>
              <img class="partner-image" src="/assets/{{siteName}}/images/pages/home/links/cryptowisser-{{darkTheme ? 'dark' : 'light'}}theme.png" alt="cryptowisser">
            </picture>
          </a>
        </div>
      </div>
      <div class="vertical-spacer my-3 my-lg-5"></div>
    </div>
  </div>

    <div class="row mb-0 mb-lg-3 mx-0 px-0 w-100 footer-wave-light">
      <img *ngIf="!darkTheme" class="footer-wave-image"
        src="{{assetsPath}}light-mode/computer_with_coins.svg">
      <img *ngIf="darkTheme" class="footer-wave-image"
        src="{{assetsPath}}dark-mode/computer_with_coins.svg">
    </div>

    <!-- footer -->
    <div class="col-12 col-xl-10 mx-auto">
      <div class="row mx-0">
        <div class="col-12 col-lg-3">
          <a class="clickable w-100 d-block d-lg-inline" (click)="scrollToTop()">
            <img src="/assets/{{siteName}}/logo.png" class="d-block mx-auto mx-lg-0" alt="" id="footer-logo">
          </a>
          <p class="footer-link mt-3 mt-lg-0 mb-2 mb-lg-0 text-center text-lg-left"><b>FOLLOW US:</b></p>
          <div class="row justify-content-center justify-content-lg-start orange-text mx-0">
            <a class="mr-1 mr-lg-0" href="https://www.instagram.com/chainexio/">
              <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/instagram.png" loading="lazy">
            </a>
            <a class="mr-1 mr-lg-0" href="https://www.facebook.com/ChainEXIO/">
              <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/facebook.png" loading="lazy">
            </a>
            <a class="mr-1 mr-lg-0" href="https://twitter.com/ChainEXIO">
              <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/twitter.png" loading="lazy">
            </a>
            <a class="mr-1 mr-lg-0" href="https://www.linkedin.com/company/chainexio/">
              <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/linkedin.png" loading="lazy">
            </a>
            <a class="mr-1 mr-lg-0" href="https://blog.chainex.io/">
              <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/ghost.png" loading="lazy">
            </a>
            <a href="https://t.me/chainexchat">
              <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/telegram_orange.png" loading="lazy">
            </a>
            <a href="https://www.youtube.com/channel/UC_3FaLikqQU46qLNr9HPoFw">
              <img src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/youtube.png" loading="lazy">
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-9 mb-3">
          <div class="row d-flex justify-content-end">
            <div class="col-12 col-lg-2 mt-4 mt-lg-0 text-center text-lg-left">
              <span class="footer-link"><b>ABOUT US:</b></span>
              <span [ngClass]="darkTheme ? 'main-links' : 'dark-main-links'">
              <a routerLink="about" class="footer-link">Company</a>
              <a href="https://support.chainex.io/open.php" class="footer-link">Contact</a>
              </span>
            </div>
            <div class="col-12 col-lg-2 mt-4 mt-lg-0 text-center text-lg-left">
              <span class="footer-link"><b>SUPPORT:</b></span>
                <span [ngClass]="darkTheme ? 'main-links' : 'dark-main-links'">
                  <a href="https://support.chainex.io/" class="footer-link">Help Centre</a>
                  <a href="https://support.chainex.io/kb/index.php" class="footer-link">Knowledge Base</a>
                  <a href="https://t.me/chainexchat" class="footer-link">Join our Telegram</a>
                  <a href="https://blog.chainex.io/" class="footer-link">Read our Blog</a>
                </span>
            </div>
            <div class="col-12 col-lg-2 mt-4 mt-lg-0 text-center text-lg-left">
              <span class="footer-link"><b>OTHER:</b></span>
              <span [ngClass]="darkTheme ? 'main-links':'dark-main-links'">
                <a routerLink="terms" class="footer-link">Terms of Service</a>
                <a routerLink="privacy" class="footer-link">Privacy Policy</a>
                <a routerLink="security" class="footer-link">Security</a>
                <a routerLink="legal" class="footer-link">Legal</a>
                <a routerLink="risk" class="footer-link">Risk Disclaimer</a>
              </span>
            </div>
            <div class="d-none d-lg-flex col-1 px-0 align-items-end">
              <a href="https://support.chainex.io/">
                <img class="w-100"
                  src="{{assetsPath + (darkTheme ? 'dark' : 'light')}}-mode/lets_chat.svg" loading="lazy">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-cl-10 mx-auto my-2">
      <div class="row mx-0">
        <div class="col-12 col-lg-10 py-4 mx-auto shadow border border-orange font-weight-bold">
          <div class="mb-3">
            Investing in cryptocurrency assets may result in the loss of capital.
          </div>

          <div>
            The data and information provided on this site are for informational purposes only, sourced from reliable places. However, it does not represent
            an offer by ChainEX to buy or sell cryptocurrencies, nor does it serve as financial advice, encouragement, or recommendation to invest in cryptocurrencies.
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-xl-10 pt-2 mx-auto">
      <span class="foot-text py-3">{{'COPYRIGHT_STRING' | i18n}}</span>
    </div>
</div>

<ng-template #downloadApp>
  <div class="col-6 pl-0 pr-2 px-md-0 mr-md-3 ml-0 d-flex justify-content-start justify-content-md-end">
    <a href="https://apps.apple.com/za/app/chainex-digital-exchange/id1544941152" target="_blank">
      <img
        class="w-100 download-img"
        src="{{assetsPath}}appstore_icon.png"
        alt="Download ChainEX APP on App Store"
      >
    </a>
  </div>

  <div class="col-6 pl-2 pr-0 px-md-0 mr-0 ml-md-3 d-flex justify-content-end justify-content-md-start">
    <a href="https://play.google.com/store/apps/details?id=io.chainex.app&hl=en&gl=US" target="_blank">
      <img
        class="w-100 download-img"
        src="{{assetsPath}}playstore_icon.png"
        alt="Download ChainEX APP on Play Store"
      >
    </a>
  </div>
</ng-template>
