import { environment } from 'src/environments/environment';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { MetaGuard } from '@ngx-meta/core';
import { AuthRerouteGuard } from '../core/services/auth-guard.service';
import { HomeModule } from './home.module';
// import { LeaderBoardComponent } from '../leader-board/leader-board.component';
import { CoinLandingComponent } from './coin-landing/coin-landing.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: {
      title: environment.config.EXCHANGE_NAME,
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' + 'Your South African Cryptocurrency Exchange',
        description: `ChainEX is a proudly South African Cryptocurrency Exchange! Join the revolution, trade, buy, \
and invest in crypto with us! Partner with ChainEX today!`,
      }
    },
    canActivate: [AuthRerouteGuard, MetaGuard]
  },
  { path: 'home', redirectTo: '', pathMatch: 'full'},
  /*{ path: 'comp', component: LeaderBoardComponent, data: { title: environment.config.EXCHANGE_NAME,
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Tomorrow\'s Trading, Today!',
      description: `Tomorrow's Trading, Today! Buy, Sell, Trade, Exchange Bitcoin for ZAR (South African Rands), \
and trade many Altcoins & Digital Assets, including Ethereum (ETH), Ripple (XRP)`,
    } }, canActivate: [AuthRerouteGuard, MetaGuard]},*/
  { path: 'coins', component: CoinLandingComponent, data: {
      title: 'Coins',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' + 'Coins',
        description: `Tomorrow's Trading, Today! Buy, Sell, Trade, Exchange Bitcoin for ZAR (South African Rands), \
and trade many Altcoins & Digital Assets, including Ethereum (ETH), Ripple (XRP)`,
      }
    },
    canActivate: [MetaGuard]
  },
  { path: 'coins/:coin', component: CoinLandingComponent, data: {
      title: 'Coins',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - Coins',
        description: `Tomorrow's Trading, Today! Buy, Sell, Trade, Exchange Bitcoin for ZAR (South African Rands), \
and trade many Altcoins & Digital Assets, including Ethereum (ETH), Ripple (XRP)`,
        'og:image': 'https://chainex.io/assets/chainex/images/meta/Homepage-FB.png',
        'twitter:image': 'https://chainex.io/assets/chainex/images/meta/Homepage-FB.png',
        'twitter:card': 'summary_large_image'
      }
    }, canActivate: [MetaGuard]
  },
  {
    path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Dashboard',
      description: environment.config.EXCHANGE_NAME + ' ' + 'Dashboard'
    } }, canActivate: [MetaGuard]
  },
];

export const routing: ModuleWithProviders<HomeModule> = RouterModule.forChild(routes);
