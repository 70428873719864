import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { asyncScheduler, BehaviorSubject, defer, merge, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import { StoreService } from 'src/app/core/services/store.service';
import { MarketsService } from 'src/app/markets/markets.service';
import * as Big from 'big-js';
import { UtilHelper } from '../../core/helpers/util-helper';
import { take } from 'rxjs/operators';

interface Market {
  id: number;
  coinId: number;
  coinCode: string;
  coinDecimals: number;
  exchangeId: number;
  exchangeCode: string;
  exchangeDecimals: number;
  lastPrice: string;
  defaultPrice: string;
  pairLabel: string;
  takerFee: string;
}

interface OrderBookEntry {
  price: number;
  amount: number;
  orderTotal: number;
  total: number;
}

@Component({
  selector: 'home-coin-price-calculator',
  templateUrl: './coin-price-calculator.component.html',
  styleUrls: ['./coin-price-calculator.component.scss']
})
export class CoinPriceCalculatorComponent implements OnInit, OnDestroy {

  @Input() darkTheme: boolean = false;
  @Input() multiMarketSelection: boolean = false;

  markets: {
    coin: string,
    coinName: string,
    exchange: string,
    exchangeName: string,
    id: string,
    icon: string,
    lastPrice: string,
    coinDecimals: number,
    exchangeDecimals: number
  }[] = [];

  exchanges: string[] = [];
  coins: string[] = [];

  readonly activeMarket: Market = {
    id: 0,
    coinId: 0,
    coinCode: '',
    coinDecimals: 0,
    exchangeId: 0,
    exchangeCode: '',
    exchangeDecimals: 0,
    lastPrice: '0',
    defaultPrice: '0',
    pairLabel: '',
    takerFee: '0'
  };

  // Multi coin, exchange controls
  coinSelectionControl: FormControl = new FormControl('BTC');
  exchangeSelectionControl: FormControl = new FormControl('ZAR');

  // Amount value controls
  coinAmountControl: FormControl = new FormControl('');
  exchangeAmountControl: FormControl = new FormControl('');

  private destroy$: Subject<void> = new Subject();
  private exchangeInfoControl: FormControl = new FormControl(0);
  private orderBookData$: BehaviorSubject<OrderBookEntry[]> = new BehaviorSubject([]);

  constructor(
    public marketService: MarketsService,
    private store: StoreService
    ) {
    }

  ngOnInit(): void {

    const exchangeChange$ = this.exchangeSelectionControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap((value) => {
        this.setActiveMarket();
      }),
      takeUntil(this.destroy$)
    );

    const coinChange$ = this.coinSelectionControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap((value) => {
        this.setActiveMarket();
      }),
      takeUntil(this.destroy$)
    );

    if (this.multiMarketSelection) {
      this.marketService.marketUpdateSubject.pipe(
        take(1),
        tap((response) => {
          this.markets = [];
          this.coins = [];

          for (const currentMarket of response) {
            if (this.coins.indexOf(currentMarket.coinCode) === -1) {
              this.coins.push(currentMarket.coinCode);
            }

            if (this.markets.filter(obj => obj.coin === currentMarket.coinCode
              && obj.exchange === currentMarket.exchangeCode)) {
              this.markets.push({
                coin: currentMarket.coinCode,
                coinName: currentMarket.coinName,
                exchange: currentMarket.exchangeCode,
                exchangeName: currentMarket.exchangeName,
                id: currentMarket.id,
                icon: currentMarket.icon,
                lastPrice: currentMarket.lastPrice,
                coinDecimals: currentMarket.coinDecimals,
                exchangeDecimals: currentMarket.exchangeDecimals
              });
            }
          }

          this.reloadExchanges(this.coinSelectionControl.value);
          this.exchanges.sort();
          this.coins.sort();
          this.setActiveMarket();
        }),
        takeUntil(this.destroy$)
      ).subscribe();

      merge(
        exchangeChange$,
        coinChange$
      ).subscribe();
    }

    const marketChange$ = this.marketService.activeMarketSubject.pipe(
      tap(() => {
        this.coinAmountControl.setValue('');
        this.exchangeAmountControl.setValue('');
        this.coinAmountControl.disable();
        this.exchangeAmountControl.disable();
      }),
      filter(market => (Object.prototype.toString.call(market) === '[object Object]') && (+market.id > 0)),
      tap(market => {
        this.activeMarket.id = +market.id;

        this.activeMarket.coinId = +market.coinId || 0;
        this.activeMarket.coinCode = market.coinCode || '';
        this.activeMarket.coinDecimals = market.coinDecimals || 0;
        this.activeMarket.exchangeId = +market.exchangeId || 0;
        this.activeMarket.exchangeCode = market.exchangeCode || '';
        this.activeMarket.exchangeDecimals = market.exchangeDecimals || 0;
        this.activeMarket.lastPrice = market.lastPrice;
        this.activeMarket.defaultPrice = market.lastPrice;
        this.activeMarket.pairLabel = market.marketPair || '';
        this.activeMarket.takerFee = typeof +market.takerFee === 'number' && !Number.isNaN(+market.takerFee)
          ? market.takerFee
          : '0';

        this.exchangeInfoControl.setValue(market.id);
      }),
      takeUntil(this.destroy$)
    );

    const orderBookChange$ = this.store.subscribe('openOrders').pipe(
      throttleTime(5000, asyncScheduler, { leading: true, trailing: true }),
      tap(() => this.exchangeInfoControl.setValue(this.activeMarket.id))
    );

    const fetchOrderInfo$ = this.exchangeInfoControl.valueChanges.pipe(
      filter(marketId => +marketId > 0),
      switchMap(marketId => {
        const data = {
          'perPage': 50,
          'pageNo': 0,
          'orderBy': 'price',
          'market': marketId,
          'decimals': 8,
          'type': 1 // for buying we want the sell order book
        };

        return this.marketService.getOrderDepth(data).pipe(
          catchError(() => of({})),
          map((response: any) => {
            let orderBookEntries: OrderBookEntry[] = [];
            // TODO: data should have been cleaned already by the service call
            //        response, but since it is not, we have to deal with it here...unfortunately
            if (
              (Object.prototype.toString.call(response) === '[object Object]') &&
              (response.response === 'success') &&
              (Array.isArray(response.sell))
            ) {
              orderBookEntries = response.sell.map((dataItem: any) => {
                const obEntry: OrderBookEntry = {
                  price: 0,
                  amount: 0,
                  orderTotal: 0,
                  total: 0
                };

                if (Object.prototype.toString.call(dataItem) === '[object Object]') {
                  obEntry.price = +dataItem.price > 0 ? +dataItem.price : obEntry.price;
                  obEntry.amount = +dataItem.amount > 0 ? +dataItem.amount : obEntry.amount;
                  obEntry.orderTotal = +dataItem.orderTotal > 0 ? +dataItem.orderTotal : obEntry.orderTotal;
                  obEntry.total = +dataItem.total > 0 ? +dataItem.total : obEntry.total;
                }

                return obEntry;
              }).filter(
                (obEntry: OrderBookEntry) => obEntry.total > 0
              ).sort((a: OrderBookEntry, b: OrderBookEntry) => a.price < b.price ? -1 : 1);
            }

            return orderBookEntries;
          }),
          tap((entries) => {
            this.orderBookData$.next(entries);

            if ((this.activeMarket.id === +marketId) && (this.activeMarket.id > 0)) {
              if (this.coinAmountControl.disabled) { this.coinAmountControl.enable(); }
              if (this.exchangeAmountControl.disabled) { this.exchangeAmountControl.enable(); }
            }
          })
        );
      }),
      takeUntil(this.destroy$)
    );

    const calculateReverseExchange$ = this.coinAmountControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(results => this.calculateReverseExchangeValue(this.orderBookData$.getValue(), +results)),
      tap(calculated => {
        const currentValue = this.coinAmountControl.value;
        if (currentValue) {
          this.activeMarket.lastPrice = UtilHelper.bigValueToFixed(
            calculated.exchangeRate,
            this.activeMarket.coinDecimals
          );
        } else {
          this.activeMarket.lastPrice = this.activeMarket.defaultPrice;
        }

        let newValue: string = '';

        if (+currentValue > 0) {
          newValue = UtilHelper.bigValueToFixed(calculated.convertedAmount, this.activeMarket.exchangeDecimals);
        } else if (!['', null].includes(currentValue)) {
          newValue = UtilHelper.bigValueToFixed(0, this.activeMarket.exchangeDecimals);
        }
        this.exchangeAmountControl.setValue(newValue, { emitEvent: false });
      }),
      takeUntil(this.destroy$)
    );

    const calculateExchange$ = this.exchangeAmountControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(results => this.calculateExchangeValue(this.orderBookData$.getValue(), +results)),
      tap(calculated => {
        const currentValue = this.exchangeAmountControl.value;

        if (currentValue) {
          this.activeMarket.lastPrice = UtilHelper.bigValueToFixed(
            calculated.exchangeRate,
            this.activeMarket.coinDecimals
          );
        } else {
          this.activeMarket.lastPrice = this.activeMarket.defaultPrice;
        }

        let newValue: string = '';

        if (+currentValue > 0) {
          newValue = UtilHelper.bigValueToFixed(calculated.convertedAmount, this.activeMarket.coinDecimals);
        } else if (!['', null].includes(currentValue) ) {
          newValue = UtilHelper.bigValueToFixed(0, this.activeMarket.coinDecimals);
        }
        this.coinAmountControl.setValue(newValue, { emitEvent: false });
      }),
      takeUntil(this.destroy$)
    );

    merge(
      calculateExchange$,
      calculateReverseExchange$,
      fetchOrderInfo$,
      orderBookChange$,
      marketChange$
    ).subscribe();

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.orderBookData$.complete();
  }

  setActiveMarket() {
    if (this.coinSelectionControl.value !== '' && this.exchangeSelectionControl.value !== '') {

      const market = this.markets.find(
        obj => obj.coin === this.coinSelectionControl.value && obj.exchange === this.exchangeSelectionControl.value
      );

      if (market) {
        const activeMarket = this.marketService.getMarketByID(market.id);
        this.marketService.setActiveMarket(activeMarket);
        this.reloadExchanges(this.coinSelectionControl.value);
      } else {
        // If market not found, reload exchange coins to form valid markets
        this.reloadExchanges(this.coinSelectionControl.value);
        this.setActiveMarket();
      }
    }
  }

  reloadExchanges(coin: string) {
    this.exchanges = this.markets.filter(obj => obj.coin === coin).map(function (obj: any) {
      return obj.exchange;
    });
    this.exchanges.sort();
    if (this.exchanges.indexOf(this.exchangeSelectionControl.value) === -1 && this.exchanges.length > 0) {
      this.exchangeSelectionControl.setValue(this.exchanges[0]);
    } else if (this.exchanges.length <= 0) {
      this.exchangeSelectionControl.setValue('');
    }
  }

  private calculateExchangeValue(
    obEntries: OrderBookEntry[],
    valueEntered: number
  ): Observable<{ exchangeRate: Big, convertedAmount: Big }> {

    return defer(() => {
      let activeFee: Big = new Big(this.activeMarket.takerFee);
      if ((this.marketService.takerFee) !== -1 && (this.marketService.takerFee < +activeFee)) {
        activeFee = new Big(this.marketService.takerFee);
      }

      let amountCalc: Big = new Big(0);
      let totalPrice: Big = new Big(0);

      if (+valueEntered > 0) {
        let netValueToOffer: Big = new Big(+valueEntered);
        const tempItems: { amount: Big, price: Big }[] = [];


        for (const obEntry of obEntries) {
          if (obEntry.amount <= 0) {
            continue;
          }

          let tempFee = new Big(obEntry.amount).times(activeFee);
          const isFiat = ['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) === -1;
          if (!isFiat) {
            tempFee = tempFee.times(obEntry.price);
          }

          const currentNetTotal = (new Big(isFiat ? obEntry.amount : obEntry.orderTotal))
            .plus(tempFee)
            .times(isFiat ? obEntry.price : 1);

          let orderTake: Big = new Big(0);
          if (netValueToOffer.minus(currentNetTotal) > 0) {
            orderTake = orderTake.add(obEntry.amount);
            netValueToOffer = netValueToOffer.minus(currentNetTotal);
          } else {
            const currentFee = (new Big(1)).plus(activeFee);
            let currentAmount: Big = netValueToOffer.div(currentFee);

            if (isFiat) {
              currentAmount = currentAmount.times(obEntry.price);
            } else {
              currentAmount = currentAmount.div(obEntry.price);
            }
            orderTake = orderTake.plus(currentAmount);
            netValueToOffer = new Big(0);
          }

          amountCalc = amountCalc.plus(orderTake);
          tempItems.push({
            amount: orderTake,
            price: new Big(obEntry.price)
          });

          if (netValueToOffer <= 0) {
            break;
          }
        }

        if (amountCalc > 0) {
          tempItems.forEach(tempItem => {
            totalPrice = totalPrice.add(tempItem.amount.div(amountCalc).times(tempItem.price));
          });
        }
      }

      return of({ exchangeRate: totalPrice as Big, convertedAmount: amountCalc as Big });
    });
  }


  private calculateReverseExchangeValue(
    obEntries: OrderBookEntry[],
    valueEntered: number
  ): Observable<{ exchangeRate: Big, convertedAmount: Big }> {

    return defer(() => {
      let activeFee: Big = new Big(this.activeMarket.takerFee);
      if ((this.marketService.takerFee) !== -1 && (this.marketService.takerFee < +activeFee)) {
        activeFee = new Big(this.marketService.takerFee);
      }

      let amountCalc: Big = new Big(0);
      let totalPrice: Big = new Big(0);

      if (+valueEntered > 0) {
        let remainingValue: Big = new Big(+valueEntered);

        const isFiat = ['ZAR', 'USDT'].indexOf(this.activeMarket.exchangeCode) > -1;

        for (const obEntry of obEntries) {
          if (obEntry.amount <= 0) {
            continue;
          }

          remainingValue = remainingValue.minus(obEntry.amount);
          const isComplete = !(remainingValue > 0);
          const tempAmount = isComplete ? (new Big(obEntry.amount)).plus(remainingValue) : new Big(obEntry.amount);

          let tempFee = tempAmount.times(activeFee);

          if (!isFiat) {
            tempFee = isComplete ? (new Big(1)).plus(activeFee) : tempFee.times(obEntry.price);
          }

          let orderNetTotal = new Big(0);
          if (isFiat) {
            orderNetTotal = orderNetTotal.plus(tempAmount).plus(tempFee).times(obEntry.price);
          } else {
            if (isComplete) {
              orderNetTotal = tempAmount.times(obEntry.price).times(tempFee);
            } else {
              orderNetTotal = orderNetTotal.plus(obEntry.orderTotal).plus(tempFee);
            }
          }

          const weight = (new Big(obEntry.amount)).plus(isComplete ? remainingValue : 0).div(+valueEntered);
          totalPrice = totalPrice.plus(weight.times(obEntry.price));
          amountCalc = amountCalc.plus(orderNetTotal);

          if (isComplete) {
            break;
          }
        }
      }

      return of({ exchangeRate: totalPrice as Big, convertedAmount: amountCalc as Big });
    });
  }
}
