<div class="row container-fluid mx-0 px-0" [ngClass]="darkTheme ? 'dark-theme-background' : 'light-theme-background'">
  <div class="d-flex justify-content-center w-100" [ngClass]="darkTheme ? 'white-text' : 'dark-text'">
    <div class="col-12 col-lg-12 col-xl-12 px-0 px-lg-1 my-auto">
      <div class="vertical-spacer d-none d-lg-block"></div>

      <!-- Wallet Value -->
      <div class="pt-3 justify-content-center row">
        <img class="mr-4 wallet" src="/assets/chainex/images/pages/dashboard/wallet.png">
        <div>
          <h2 i18n>{{ totalFiat }}<br></h2>
          <h6>Total Estimated Wallet Value</h6>
        </div>
      </div>
      <div class="vertical-spacer d-none d-lg-block"></div>

      <!-- Quick Trade -->
      <div class="col-12 col-lg-8 mx-auto">
        <div class="mt-0 pt-3 text-center">
          <h2 class="mb-4" i18n>Quick<span class="orange-text"><b> Trade</b></span></h2>
        </div>
        <div class="py-5 text-center" [ngClass]="darkTheme ? 'background-block-dark' : 'background-block-light'">
          <h6>Select the coin that you would like to <span class="orange-text"><b>Buy</b></span> or <span class="orange-text"><b>Sell</b></span></h6>
          <div class="pt-4 pb-2 justify-content-center row" *ngIf="coins">
            <div class="px-3">
              <button (click)="scrollLeft()" class="arrow left mt-4"></button>
            </div>
            <div #coinCard class="scroll">
              <div class="coin-card" *ngFor="let coin of coins" [class.selected]="selectedCoin?.coinCode === coin.coinCode">
                <a class="mat-button" (click)="selectCoin(coin)">
                  <img class="coin-img" [src]="imageUrl + coin.coinCode.toLowerCase() + '.png'" (error)="$event.target.src = imageUrl + 'fallback_coin_icon.png'">
                </a>
                <h6 class="pt-1">{{ coin.coinCode }}</h6>
              </div>
            </div>
            <div class="px-3">
              <button (click)="scrollRight()" class="arrow right mt-4"></button>
            </div>
          </div>
          <div *ngIf="selectedCoin">
            <div class="text-center pt-2">
              <h4>{{ lastPrice }}</h4>
              <p>Last Traded Price</p>
            </div>
          </div>
          <div class="col-12 pt-3 row mx-auto px-3 justify-content-center">
            <a (click)="openQuickDialog(0)" class="mat-raised-button mat-primary orange-gradient col-12 col-sm-3 py-2 mx-3 main-button" i18n>
              QUICK BUY
            </a>
            <a (click)="openQuickDialog(1)" class="mat-raised-button mat-primary orange-gradient col-12 col-sm-3 py-2 mx-3 main-button" i18n>
              QUICK SELL
            </a>
          </div>
          <h6>For advance trading, head to our markets page <a class="orange-text no-underline" routerLink="/markets"><b>HERE</b></a></h6>
        </div>
      </div>
      <div class="vertical-spacer d-none d-lg-block"></div>

      <!-- Your Wallet -->
      <div class="col-12 col-lg-10 mx-auto">
        <div class="mt-0 pb-3 text-center">
          <h2 class="mt-5 mb-3" i18n>Your<span class="orange-text"><b> Wallets</b></span></h2>
        </div>
        <div [ngClass]="darkTheme ? 'background-block-dark' : 'background-block-light'">
          <div [ngClass]="darkTheme ? 'border-frame-dark' : 'border-frame-light'">
            <app-balance-cards></app-balance-cards>
          </div>
        </div>
        <div class="pt-3 text-center">
          <h5 class="orange-text"><a class="no-underline" routerLink="/balances">Show More</a></h5>
        </div>
      </div>
      <div class="vertical-spacer d-none d-lg-block"></div>

      <!-- Earn Portal -->
      <div class="col-12 col-lg-10 mx-auto">
        <div class="mt-0 pt-3 text-center">
          <h2 class="my-4" i18n>Earn<span class="orange-text"><b> Portal</b></span></h2>
        </div>
        <div class="py-3 px-3" [ngClass]="darkTheme ? 'border-frame-dark' : 'border-frame-light'">
          <app-earn-portal [earnPage]="true" [dashboard]="true"></app-earn-portal>
        </div>
      </div>
      <div class="vertical-spacer d-none d-lg-block"></div>

      <!-- Your Referrals -->
      <div class="col-12 col-lg-8 mx-auto">
        <div class="mt-0 pt-3 text-center">
          <h2 class="my-4" i18n>Your<span class="orange-text"><b> Referrals</b></span></h2>
        </div>
        <div class="pt-5 pb-3 px-5" [ngClass]="darkTheme ? 'background-block-dark' : 'background-block-light'">
          <div class="py-5 px-5" [ngClass]="darkTheme ? 'dark-theme-background' : 'light-theme-background'">

            <div class="pb-2" *ngIf="referralsummary.total.level1 >= 1">
              <h4 class="orange-text pb-3">Your Referrals Per Level:</h4>
              <div class="row justify-content-around">
                <div class="text-center">
                  <h4>{{ referralsummary.total.level1 }}</h4>
                  <h6 class="orange-text"><span class="mobile">Level</span> 1</h6>
                </div>
                <div class="text-center">
                  <h4>{{ referralsummary.total.level2 }}</h4>
                  <h6 class="orange-text"><span class="mobile">Level</span> 2</h6>
                </div>
                <div class="text-center">
                  <h4>{{ referralsummary.total.level3 }}</h4>
                  <h6 class="orange-text"><span class="mobile">Level</span> 3</h6>
                </div>
                <div class="text-center">
                  <h4>{{ referralsummary.total.level4 }}</h4>
                  <h6 class="orange-text"><span class="mobile">Level</span> 4</h6>
                </div>
              </div>
            </div>

            <div class="text-center" *ngIf="referralsummary.total.level1 < 1">
              <h4 class="orange-text pb-3">Refer and get Rewarded</h4>
              <p>Invite your friends to start trading and earn up to 16% of their trading fees. You will also receive rewards off trades from traders they refer thereafter!</p>
              <div class="col-12 col-xl-4 pt-2 mb-5 mb-lg-3 mx-auto">
                <a href="/refer" class="mat-raised-button mat-primary orange-gradient w-100 main-button" i18n>Learn More</a>
              </div>
            </div>

            <div class="pt-4">
              <h4 class="orange-text pb-2">Your Referral Sign Ups:</h4>
              <div class="row align-items-center mt-0">
                <div class="col-12 col-sm-4 col-xl-5 table-heading pr-0" i18n>Your referral code:</div>
                <div class="col-10 col-md-5">
                  <mat-form-field class="referral-field mb-0 mt-0">
                    <input readonly matInput [value]="referralCode">
                  </mat-form-field>
                </div>
                <div class="col-2 col-md-1 px-0">
                  <mat-icon
                    color="primary"
                    (click)="copyToClipboard(referralCode)"
                    i18n-matTooltip
                    matTooltip="Copy Referral Code"
                  >
                    file_copy
                  </mat-icon>
                </div>
              </div>

              <div class="row align-items-center mt-0">
                <div class="col-12 col-sm-4 col-xl-5 table-heading pr-0" i18n>Your sign up link:</div>
                <div class="col-10 col-md-5">
                  <mat-form-field class="referral-field mb-0 mt-0">
                    <input readonly matInput [value]="referralUrl">
                  </mat-form-field>
                </div>
                <div class="col-2 col-md-1 px-0">
                  <mat-icon
                    color="primary"
                    (click)="copyToClipboard(referralUrl)"
                    data-container="body"
                    i18n-matTooltip
                    matTooltip="Copy Referral Link"
                  >
                    file_copy
                  </mat-icon>
                </div>
              </div>

              <div class="row align-items-center mt-0">
                <div class="col-sm-4 col-xl-5 table-heading pr-0" i18n>Invite via email:</div>
                <div class="col-10 col-md-5">
                  <mat-form-field class="referral-field mb-0 mt-0">
                    <input matInput [attr.disabled]="limitReached" type="email" (keyup)="emailKeyPress($event)"
                      autocomplete="off" [formControl]="emailControl" [(ngModel)]="referralEmail">
                    <mat-error *ngIf="!emailError && (emailControl.hasError('email') || emailControl.hasError('required'))" i18n>
                      Please provide a valid email address.
                    </mat-error>
                    <mat-error *ngIf="emailError" i18n>
                      {{ emailErrorMessage }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-2 col-md-1 px-0">
                  <mat-icon
                    color="primary"
                    (click)="emailInvite(referralEmail)"
                    i18n-matTooltip
                    matTooltip="To invite new traders to sign up with your referral code via email, simply enter their email address here and hit send"
                  >
                    send
                  </mat-icon>
                </div>
              </div>
            </div>
          </div>
          <h6 class="pt-3 text-center orange-text"><a class="no-underline" routerLink="/refer">Manage Referrals</a></h6>
        </div>
      </div>
      <div class="vertical-spacer d-none d-lg-block"></div>
    </div>
  </div>
</div>
