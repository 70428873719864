import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';

import { routing } from './home.routing';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { DecimalNumberPipe } from '../core/pipe/decimalNumber.pipe';
import { ImageTileComponent } from './image-tile/image-tile.component';
import { CoinLandingComponent } from './coin-landing/coin-landing.component';
import { CoinPriceCalculatorComponent } from '../shared/coin-price-calculator/coin-price-calculator.component';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    routing,
    HttpClientModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    MatGridListModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    ChartsModule,
    MatMenuModule,
    MatSortModule,
    MatCardModule,
    SharedModule
  ],
  declarations: [
    HomeComponent,
    DashboardComponent,
    ImageTileComponent,
    CoinLandingComponent,
    CoinPriceCalculatorComponent],
  providers: [DecimalNumberPipe],
  entryComponents: [MatIcon],
})
export class HomeModule { }
