<div *ngIf="layout === 'col'; else rowLayout" class="px-0 px-md-1 px-lg-2">
  <div [ngClass]="darkTheme ? 'dark-image-box':'image-box'"  class="d-flex flex-column justify-content-center mx-auto my-4 px-0 shadow">
  <img *ngIf="!darkTheme" src="{{lightImagePath + image}}" class="tile-image" loading="lazy">
  <img *ngIf="darkTheme" src="{{darkImagePath + image}}" class="tile-image" loading="lazy">
  <h4 class="text-center d-block px-1" i18n>{{text}}</h4>
  </div>
</div>

<ng-template #rowLayout>
  <div class="row mb-4 d-flex flex-row align-items-center">
    <div class="col-12 col-lg-3 px-0">
      <a *ngIf="link; else noLink" href="{{link}}">
        <img *ngIf="!darkTheme" src="{{lightImagePath + image}}" class="mx-auto pl-0 pl-lg-3 row-tile-image" loading="lazy">
        <img *ngIf="darkTheme" src="{{darkImagePath + image}}" class="mx-auto pl-0 pl-lg-3 row-tile-image" loading="lazy">
      </a>
      <ng-template #noLink>
        <img *ngIf="!darkTheme" src="{{lightImagePath + image}}" class="mx-auto pl-0 pl-lg-3 row-tile-image" loading="lazy">
        <img *ngIf="darkTheme" src="{{darkImagePath + image}}" class="mx-auto pl-0 pl-lg-3 row-tile-image" loading="lazy">
      </ng-template>
    </div>
    <h4 class="col-12 col-lg-9 px-1 px-lg-3 px-xl-1 mb-0 mt-3 mt-lg-0 text-center text-lg-left d-block" i18n>{{text}}</h4>
  </div>
</ng-template>
