import { Component, OnDestroy, OnInit } from '@angular/core';
import { concat, merge, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { IMarketData } from 'src/app/core/interfaces/home-interface';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { HistoryService, CoinData } from 'src/app/history/history.service';
import { MarketsService } from 'src/app/markets/markets.service';
import { HomeService } from '../home.service';
import { MetaService } from '@ngx-meta/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'home-coin-landing',
  templateUrl: './coin-landing.component.html',
  styleUrls: ['./coin-landing.component.scss']
})
export class CoinLandingComponent implements OnInit, OnDestroy {

  imageUrl: string = 'assets/images/coins/';
  markets: any;
  filteredMarkets: any;
  graphPoints: Array<any> = [];
  selectedMarket: any;
  coinInfos: CoinData[];
  selectedCoin: CoinData;
  routeCoin: string;
  marketCoinSelected: string;
  marketExchangeSelected: string;
  marketDataSelected: any;
  chartResting: boolean = false;
  darkTheme: boolean = false;

  showITB: boolean = false;
  showCoinInfo: boolean = false;
  moreCoins: boolean = false;
  coinCardMarkets: any[] = [];
  filteredCoinCards: any[] = [];
  loggedIn: boolean;

  coins: string[] = [];
  filteredCoins: string[] = [];
  exchanges: string[] = [];
  coinPrices: IMarketData[] = [];

  private readonly supportedCoins: string[];
  private destroy$: Subject<void> = new Subject();

  constructor(
    private sessionStorage: SessionStorageService,
    private marketService: MarketsService,
    private homeService: HomeService,
    private historyService: HistoryService,
    private route: ActivatedRoute,
    private readonly meta: MetaService,
  ) {
    this.supportedCoins = this.marketService.supportedCoinsITB;
  }

  ngOnInit(): void {
    const findSelectCoin = () => {
      if (this.coinInfos && this.routeCoin) {
        const coin = this.coinInfos.find(coinData => (
          coinData.code && (
            coinData.code.toLowerCase() === this.routeCoin.toLowerCase() ||
            coinData.name.toLowerCase() === this.routeCoin.toLowerCase())));
        if (coin) {
          this.selectCoin(coin);
        } else {
          this.routeCoin = '';
        }
      }
    };

    this.route.params.subscribe(params => {
      this.routeCoin = params.coin;
      findSelectCoin();
    });

    this.markets = [];
    this.selectedCoin = {
      id: 0,
      code: '',
      name: '',
      defaultNetwork: '',
      extraData: {
        information: [],
        website: '',
        blockExplorer: '',
        twitterLink: '',
        facebookLink: '',
        instagramLink: '',
        redditLink: '',
        githubLink: '',
        telegramLink: '',
        youtubeLink: '',
        networkBlockExplorers: []
      }
    };

    const history$ = this.historyService.getAllCoins().pipe(
      tap((response: CoinData[]) => {
        this.coinInfos = response;
        // make a copy for coin cards
        this.filteredCoinCards = this.coinInfos;
        findSelectCoin();
      })
    );

    // fetch markets only once on init
    const market$ = this.marketService.marketUpdateSubject.asObservable().pipe(
      distinctUntilChanged((prevMarkets, currMarkets) =>
        (prevMarkets.map(pm => currMarkets.findIndex(cm => cm.id === pm.id && cm.lastPrice === pm.lastPrice))
          .findIndex(z => z === -1) === -1)
      ),
      tap((response) => {
        const iniCalculator = !(this.markets.length > 0);
        this.coins = [];
        this.markets = [];

        for (const currentMarket of response) {
          if (this.coins.indexOf(currentMarket.exchangeCode) === -1) {
            this.coins.push(currentMarket.exchangeCode);
          }

          if (this.markets.findIndex(obj => obj.coin === currentMarket.coinCode
            && obj.exchange === currentMarket.exchangeCode)) {

            this.markets.push({
              coin: currentMarket.coinCode,
              coinName: currentMarket.coinName,
              exchange: currentMarket.exchangeCode,
              exchangeName: currentMarket.exchangeName,
              id: currentMarket.id,
              icon: currentMarket.icon,
              lastPrice: currentMarket.lastPrice,
              coinDecimals: currentMarket.coinDecimals,
              exchangeDecimals: currentMarket.exchangeDecimals
            });
          }
        }

        if (this.markets.length > 0 && iniCalculator) {
          // initialise coin price calculator
          this.filterMarkets();
          this.exchanges.sort();
          this.generateMarketStats();
        }

        // make markets copy for each coin
        for (const coin of this.coinInfos) {
          this.coinCardMarkets[coin.id] = this.markets.filter(a => a.coin === coin.code);
        }
      }),

      takeUntil(this.destroy$)
    );

    const theme$ = this.sessionStorage.observe('THEME').pipe(
      tap(data => this.darkTheme = data === 'dark-theme'),
      takeUntil(this.destroy$)
    );

    const data$ = concat(
      history$,
      market$
    ).pipe(
      takeUntil(this.destroy$)
    );

    merge(
      data$,
      theme$
    ).subscribe();

    if (this.sessionStorage.get('LOGGED_IN') !== '' &&
      this.sessionStorage.get('TOKEN') !== '') {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(val: string) {
    this.filteredCoinCards = this.coinInfos.filter(coin =>
      coin.name.toLocaleLowerCase().indexOf(val.trim().toLocaleLowerCase()) !== -1 ||
      coin.code.toLocaleLowerCase().indexOf(val.trim().toLocaleLowerCase()) !== -1
    );
  }

  coinCardsToggle() {
    if (!this.showCoinInfo) {
      this.showCoinInfo = true;
    } else {
      // more coin cards toggle
      this.moreCoins = !this.moreCoins;
    }
  }

  public setCoinMetaTitle(coin: CoinData) {
    this.meta.setTitle(environment.config.EXCHANGE_NAME + ` - Coin Info - ${coin.name} (${coin.code})`);
    const description = coin.extraData.information[0];

    this.meta.setTag('description', description);
    this.meta.setTag('og:description', description);
    this.meta.setTag('twitter:description', description);
  }

  // filter markets and coin lists to the selected exchange
  private filterMarkets() {
    this.filteredMarkets = this.markets.filter(obj => obj.coin === this.marketExchangeSelected);
    this.filteredCoins = [];
    this.filteredMarkets.forEach(filteredMarket => {
      if (this.filteredCoins.indexOf(filteredMarket.coinCode) === -1) {
        this.filteredCoins.push(filteredMarket.coin);
      }
    });
    this.filteredCoins.sort();

    if (this.filteredCoins.length > 0) {
      this.setSelectedMarket(this.filteredMarkets[0]);
    } else {
      this.showITB = false;
    }

  }

  private generateChartData(getData: boolean = false) {
    if (this.chartResting && !getData) {
      return;
    }

    this.chartResting = true;

    let chartPair: string;
    const oneWeek: number = 604800;
    const endDate: number = Date.now() / 1000;
    const startDate: number = (endDate - oneWeek);
    const temp: number = this.coinPrices.length;
    const points: any = { pair: '', data: [0, 0, 0, 0, 0, 0, 0] };

    for (let i = 0; i < this.coinPrices.length; i++) {
      chartPair = this.coinPrices[i].marketPair;
      if (this.graphPoints.length === 0) {
        this.coinPrices[i].chartData = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '' }];
      } else {
        for (let cp = 0; cp < this.graphPoints.length; cp++) {
          if (this.graphPoints[cp].pair === chartPair) {
            this.coinPrices[cp].chartData = [{ data: this.graphPoints[cp].data, label: '' }];
          }
        }
      }

      this.homeService.getChartData({
        symbol: chartPair, resolution: '1D',
        from: startDate, to: endDate
      }).subscribe((response) => {
        if (!!response.c) {
          this.coinPrices[i].chartData = [{ data: response.c, label: '' }];
          points.pair = chartPair;
          points.data = response.c;
          this.graphPoints = [];
          this.graphPoints.push(points);
        } else {
          this.coinPrices[i].chartData = [{ data: [0, 0, 0, 0, 0, 0, 0], label: '' }];
        }
      });
      if (i === temp - 1) {
        this.coinPrices = this.coinPrices;
        this.generateChartData();
      }
    }
  }

  // set up the market graph
  private generateMarketStats() {
    this.marketService.getMarketPairsWithStats().subscribe(response => {
      const stats: IMarketData[] = [];
      let normStat: IMarketData = null;
      const marketPairs: string[] = [];
      for (let i = 0; i < this.filteredMarkets.length; i++) {
        marketPairs.push(`${this.filteredMarkets[i].coin}/${this.filteredMarkets[i].exchange}`);
      }

      for (let i = 0; i < response.data.length; i++) {
        if (marketPairs.findIndex(mp => mp === response.data[i].marketPair) !== -1) {
          normStat = { ...response.data[i] };
          normStat.type = '';
          normStat.text = '';
          stats.push(normStat);
        }

        if (response.data.length - 1 === i) {
          this.coinPrices = [];
          if (this.coinPrices.length === 0) {
            this.coinPrices = stats;
          }

          this.setMarketData();
          this.generateChartData(true);
        }
      }
    });
  }

  private setMarketData() {
    if (this.selectedMarket && this.selectedMarket.coin) {
      const newStatMarket = this.coinPrices.filter(
        (obj) => obj.coinCode === this.selectedMarket.coin && obj.exchangeCode === this.selectedMarket.exchange);
      if (newStatMarket.length > 0) {
        this.marketDataSelected = newStatMarket[0];
      }
    }
  }

  setSelectedMarket(market: any) {
    this.selectedMarket = market;
    this.marketExchangeSelected = this.selectedMarket.exchange;
    this.setMarketData();

    if (this.filteredCoins.length > 0 && this.filteredCoins.indexOf(this.selectedMarket.coin) === -1) {
      this.marketCoinSelected = this.filteredCoins[0];
    } else {
      this.marketCoinSelected = this.selectedMarket.coin;
    }
    const marketDetails = this.marketService.getMarketByID(this.selectedMarket.id);

    this.clearTabs();
    this.selectedMarket.tabSelected = true;

    // update marketService for stats displayed
    this.marketService.setActiveMarket(marketDetails);
  }

  // set coin info displayed and load market information
  selectCoin(coin: any) {
    this.selectedCoin = coin;
    this.setCoinMetaTitle(coin);
    this.marketExchangeSelected = coin.code; // for coin price calculator
    this.filterMarkets();
    this.generateMarketStats();
    this.coinCardsToggle();
    this.showITB = this.supportedCoins.includes(coin.code.toUpperCase());
    // initialize filtered coins and scroll top
    this.filteredCoinCards = this.coinInfos;
    document.getElementById('coinInfo').scrollIntoView();
  }

  reloadCoins(exchange: string) {
    this.coins = this.markets.filter(obj => obj.exchange === exchange).map(function (obj: any) {
      return obj.coin;
    });
    this.coins.sort();
  }

  clearTabs() {
    // initialize tabs select
    for (let i = 0; i < this.filteredMarkets.length; i++) {
      this.filteredMarkets[i].tabSelected = false;
    }
  }
}
